/* tslint:disable */
/* eslint-disable */
/**
 * Nehnutelnosti Nest
 * Nehnutelnosti Nest
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdvertisementAmenityResponseAmenityAmenityItemDto
 */
export interface AdvertisementAmenityResponseAmenityAmenityItemDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementAmenityResponseAmenityAmenityItemDto
     */
    max: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementAmenityResponseAmenityAmenityItemDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementAmenityResponseAmenityAmenityItemDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AdvertisementAmenityResponseAmenityAmenityTotalDto
 */
export interface AdvertisementAmenityResponseAmenityAmenityTotalDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementAmenityResponseAmenityAmenityTotalDto
     */
    max: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementAmenityResponseAmenityAmenityTotalDto
     */
    value: number;
}
/**
 * 
 * @export
 * @interface AdvertisementAmenityResponseAmenityDto
 */
export interface AdvertisementAmenityResponseAmenityDto {
    /**
     * 
     * @type {AdvertisementAmenityResponseAmenityAmenityTotalDto}
     * @memberof AdvertisementAmenityResponseAmenityDto
     */
    total: AdvertisementAmenityResponseAmenityAmenityTotalDto;
    /**
     * 
     * @type {Array<AdvertisementAmenityResponseAmenityAmenityItemDto>}
     * @memberof AdvertisementAmenityResponseAmenityDto
     */
    items: Array<AdvertisementAmenityResponseAmenityAmenityItemDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementAmenityResponseDto
 */
export interface AdvertisementAmenityResponseDto {
    /**
     * 
     * @type {AdvertisementAmenityResponseAmenityDto}
     * @memberof AdvertisementAmenityResponseDto
     */
    amenity?: AdvertisementAmenityResponseAmenityDto;
}
/**
 * 
 * @export
 * @interface AdvertisementBreadcrumbItemCategoryDto
 */
export interface AdvertisementBreadcrumbItemCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementBreadcrumbItemCategoryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemCategoryDto
     */
    sefName: string;
}
/**
 * 
 * @export
 * @interface AdvertisementBreadcrumbItemDto
 */
export interface AdvertisementBreadcrumbItemDto {
    /**
     * 
     * @type {AdvertisementBreadcrumbItemLocationDto}
     * @memberof AdvertisementBreadcrumbItemDto
     */
    location?: AdvertisementBreadcrumbItemLocationDto;
    /**
     * 
     * @type {AdvertisementBreadcrumbItemCategoryDto}
     * @memberof AdvertisementBreadcrumbItemDto
     */
    category?: AdvertisementBreadcrumbItemCategoryDto;
    /**
     * 
     * @type {AdvertisementBreadcrumbItemTransactionDto}
     * @memberof AdvertisementBreadcrumbItemDto
     */
    transaction?: AdvertisementBreadcrumbItemTransactionDto;
}
/**
 * 
 * @export
 * @interface AdvertisementBreadcrumbItemLocationDto
 */
export interface AdvertisementBreadcrumbItemLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemLocationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemLocationDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemLocationDto
     */
    type: AdvertisementBreadcrumbItemLocationDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementBreadcrumbItemLocationDtoTypeEnum {
    Country = 'COUNTRY',
    County = 'COUNTY',
    District = 'DISTRICT',
    City = 'CITY',
    CityPart = 'CITY_PART',
    Street = 'STREET',
    StreetNumber = 'STREET_NUMBER'
}

/**
 * 
 * @export
 * @interface AdvertisementBreadcrumbItemTransactionDto
 */
export interface AdvertisementBreadcrumbItemTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementBreadcrumbItemTransactionDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemTransactionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementBreadcrumbItemTransactionDto
     */
    sefName: string;
}
/**
 * 
 * @export
 * @interface AdvertisementContactDto
 */
export interface AdvertisementContactDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementContactDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementContactDto
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementContactDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementContactDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementContactDto
     */
    type: AdvertisementContactDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementContactDtoTypeEnum {
    MoreInfo = 'MORE_INFO',
    Inspection = 'INSPECTION',
    FloorPlan = 'FLOOR_PLAN'
}

/**
 * 
 * @export
 * @interface AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto
 */
export interface AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto
     */
    category: AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDtoCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDtoCategoryEnum {
    NUMBER_null = null
}

/**
 * 
 * @export
 * @interface AdvertisementDetaiDevProjectsExclusiveProjectDto
 */
export interface AdvertisementDetaiDevProjectsExclusiveProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    detailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementPriceInfoDto}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    priceInfo: AdvertisementDetailAdvertisementPriceInfoDto;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementLocationInfoDto}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    locationInfo: AdvertisementDetailAdvertisementLocationInfoDto;
    /**
     * 
     * @type {Array<DevProjectsExclusiveProjectAdvertisementsInfoDto>}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    advertisementsInfo: Array<DevProjectsExclusiveProjectAdvertisementsInfoDto>;
    /**
     * 
     * @type {DevProjectsExclusiveProjectFlagsDto}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    flags: DevProjectsExclusiveProjectFlagsDto;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementAdvertiserDto}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    advertiser: AdvertisementDetailAdvertisementAdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectDto
     */
    realEstateState: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto
 */
export interface AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto
     */
    isPremium: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementDetaiDevProjectsExclusiveProjectsDto
 */
export interface AdvertisementDetaiDevProjectsExclusiveProjectsDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectsDto
     */
    countySlugName?: string;
    /**
     * 
     * @type {Array<AdvertisementDetaiDevProjectsExclusiveProjectDto>}
     * @memberof AdvertisementDetaiDevProjectsExclusiveProjectsDto
     */
    projects: Array<AdvertisementDetaiDevProjectsExclusiveProjectDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertisementAdvertiserDto
 */
export interface AdvertisementDetailAdvertisementAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementAdvertiserDto
     */
    agencyName: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertisementCategoryDto
 */
export interface AdvertisementDetailAdvertisementCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementCategoryDto
     */
    mainValue: AdvertisementDetailAdvertisementCategoryDtoMainValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementCategoryDto
     */
    subValue: AdvertisementDetailAdvertisementCategoryDtoSubValueEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailAdvertisementCategoryDtoMainValueEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}
/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailAdvertisementCategoryDtoSubValueEnum {
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertisementLocationInfoDto
 */
export interface AdvertisementDetailAdvertisementLocationInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    locationString?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementLocationInfoDto
     */
    country: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertisementPriceInfoDto
 */
export interface AdvertisementDetailAdvertisementPriceInfoDto {
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof AdvertisementDetailAdvertisementPriceInfoDto
     */
    pricePrimary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof AdvertisementDetailAdvertisementPriceInfoDto
     */
    priceSecondary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {AdvertisementsListResultPriceInfPowerCostsDto}
     * @memberof AdvertisementDetailAdvertisementPriceInfoDto
     */
    powerCosts?: AdvertisementsListResultPriceInfPowerCostsDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementPriceInfoDto
     */
    noteToPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertisementPriceInfoDto
     */
    priceOption?: AdvertisementDetailAdvertisementPriceInfoDtoPriceOptionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailAdvertisementPriceInfoDtoPriceOptionEnum {
    PriceByAgreement = 'PRICE_BY_AGREEMENT',
    InfoInRealEstateOffice = 'INFO_IN_REAL_ESTATE_OFFICE'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertiserAgencyDto
 */
export interface AdvertisementDetailAdvertiserAgencyDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    slugName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    webPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    logoUrl?: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertiserLocationDto}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    location: AdvertisementDetailAdvertiserLocationDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailAdvertiserAgencyDto
     */
    isProfi: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertiserBranchDto
 */
export interface AdvertisementDetailAdvertiserBranchDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserBranchDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserBranchDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserBranchDto
     */
    slugName: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertiserLocationDto}
     * @memberof AdvertisementDetailAdvertiserBranchDto
     */
    location: AdvertisementDetailAdvertiserLocationDto;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertiserDto
 */
export interface AdvertisementDetailAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    type: AdvertisementDetailAdvertiserDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    slugName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    mainPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    isPro: boolean;
    /**
     * 
     * @type {AdvertisementDetailAdvertiserAgencyDto}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    agency?: AdvertisementDetailAdvertiserAgencyDto;
    /**
     * 
     * @type {AdvertisementDetailAdvertiserBranchDto}
     * @memberof AdvertisementDetailAdvertiserDto
     */
    branch?: AdvertisementDetailAdvertiserBranchDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailAdvertiserDtoTypeEnum {
    Agent = 'AGENT',
    PrivatePerson = 'PRIVATE_PERSON'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailAdvertiserLocationDto
 */
export interface AdvertisementDetailAdvertiserLocationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvertisementDetailAdvertiserLocationDto
     */
    parts: Array<string>;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailBreadcrumbDto
 */
export interface AdvertisementDetailBreadcrumbDto {
    /**
     * 
     * @type {AdvertisementBreadcrumbItemDto}
     * @memberof AdvertisementDetailBreadcrumbDto
     */
    main: AdvertisementBreadcrumbItemDto;
    /**
     * 
     * @type {Array<AdvertisementBreadcrumbItemDto>}
     * @memberof AdvertisementDetailBreadcrumbDto
     */
    parts: Array<AdvertisementBreadcrumbItemDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailDevProjectDto
 */
export interface AdvertisementDetailDevProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDevProjectDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDevProjectDto
     */
    relationId: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDevProjectDto
     */
    slugName: string;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailDevProjectDto
     */
    numberOfActiveAdvertisements: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDevProjectDto
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailDevProjectDto
     */
    yearOfConstruction?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailDevProjectDto
     */
    hasShow: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailDto
 */
export interface AdvertisementDetailDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailDto
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    publishAt?: string;
    /**
     * 
     * @type {AdvertisementDetailBreadcrumbDto}
     * @memberof AdvertisementDetailDto
     */
    breadcrumb: AdvertisementDetailBreadcrumbDto;
    /**
     * 
     * @type {AdvertisementDetailMediaDto}
     * @memberof AdvertisementDetailDto
     */
    media: AdvertisementDetailMediaDto;
    /**
     * 
     * @type {AdvertisementDetailFlagsDto}
     * @memberof AdvertisementDetailDto
     */
    flags: AdvertisementDetailFlagsDto;
    /**
     * 
     * @type {AdvertisementDetailDevProjectDto}
     * @memberof AdvertisementDetailDto
     */
    devProject?: AdvertisementDetailDevProjectDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    slugTitle: string;
    /**
     * 
     * @type {AdvertisementDetailLocationDto}
     * @memberof AdvertisementDetailDto
     */
    location: AdvertisementDetailLocationDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailDto
     */
    description: string;
    /**
     * 
     * @type {AdvertisementDetailParametersDto}
     * @memberof AdvertisementDetailDto
     */
    parameters: AdvertisementDetailParametersDto;
    /**
     * 
     * @type {AdvertisementDetailAdvertiserDto}
     * @memberof AdvertisementDetailDto
     */
    advertiser: AdvertisementDetailAdvertiserDto;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailFlagsDto
 */
export interface AdvertisementDetailFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailFlagsDto
     */
    isPremium: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailListingAdvertisementLocationDto
 */
export interface AdvertisementDetailListingAdvertisementLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementLocationDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementLocationDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementLocationDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementLocationDto
     */
    city?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto
 */
export interface AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto
     */
    slugName: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailListingAdvertisementsAdvertiserDto
 */
export interface AdvertisementDetailListingAdvertisementsAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    isPro: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    slugName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    advertiserType: AdvertisementDetailListingAdvertisementsAdvertiserDtoAdvertiserTypeEnum;
    /**
     * 
     * @type {AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto}
     * @memberof AdvertisementDetailListingAdvertisementsAdvertiserDto
     */
    agency: AdvertisementDetailListingAdvertisementsAdvertiserAgencyDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailListingAdvertisementsAdvertiserDtoAdvertiserTypeEnum {
    Agent = 'AGENT',
    PrivatePerson = 'PRIVATE_PERSON'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailLocationDto
 */
export interface AdvertisementDetailLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    countySlug?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    cityPart?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailLocationDto
     */
    streetNumber?: string;
    /**
     * 
     * @type {AdvertisementDetailLocationPointDto}
     * @memberof AdvertisementDetailLocationDto
     */
    point?: AdvertisementDetailLocationPointDto;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailLocationPointDto
 */
export interface AdvertisementDetailLocationPointDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailLocationPointDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailLocationPointDto
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailLocationPointDto
     */
    radius: number;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailMediaDto
 */
export interface AdvertisementDetailMediaDto {
    /**
     * 
     * @type {Array<AdvertisementDetailMediaPhotoDto>}
     * @memberof AdvertisementDetailMediaDto
     */
    photos: Array<AdvertisementDetailMediaPhotoDto>;
    /**
     * 
     * @type {Array<AdvertisementDetailMediaVideoDto>}
     * @memberof AdvertisementDetailMediaDto
     */
    videos: Array<AdvertisementDetailMediaVideoDto>;
    /**
     * 
     * @type {Array<AdvertisementDetailMediaFloorPlanDto>}
     * @memberof AdvertisementDetailMediaDto
     */
    floorPlans: Array<AdvertisementDetailMediaFloorPlanDto>;
    /**
     * 
     * @type {Array<AdvertisementDetailMediaInspection3dDto>}
     * @memberof AdvertisementDetailMediaDto
     */
    inspections3d: Array<AdvertisementDetailMediaInspection3dDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailMediaFloorPlanDto
 */
export interface AdvertisementDetailMediaFloorPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaFloorPlanDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailMediaInspection3dDto
 */
export interface AdvertisementDetailMediaInspection3dDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaInspection3dDto
     */
    type: AdvertisementDetailMediaInspection3dDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaInspection3dDto
     */
    url: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailMediaInspection3dDtoTypeEnum {
    Ths = 'THS',
    Pivo = 'PIVO',
    Tour = 'TOUR',
    Revio = 'REVIO',
    Kuula = 'KUULA',
    Vimeo = 'VIMEO',
    Cupix = 'CUPIX',
    Theasys = 'THEASYS',
    Vieweet = 'VIEWEET',
    Istaging = 'ISTAGING',
    Insta360 = 'INSTA360',
    Lapentor = 'LAPENTOR',
    Eyespy360 = 'EYESPY360',
    Cloudpano = 'CLOUDPANO',
    Drealitna = 'DREALITNA',
    Mollyinvr = 'MOLLYINVR',
    Grandpano = 'GRANDPANO',
    Matterport = 'MATTERPORT',
    Momento360 = 'MOMENTO360',
    Feelestate = 'FEELESTATE',
    Giraffe360 = 'GIRAFFE360'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailMediaPhotoDto
 */
export interface AdvertisementDetailMediaPhotoDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaPhotoDto
     */
    origUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaPhotoDto
     */
    mediumUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaPhotoDto
     */
    smallUrl: string;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailMediaVideoDto
 */
export interface AdvertisementDetailMediaVideoDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaVideoDto
     */
    type: AdvertisementDetailMediaVideoDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailMediaVideoDto
     */
    url: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailMediaVideoDtoTypeEnum {
    Youtube = 'YOUTUBE',
    Videoobhliadky = 'VIDEOOBHLIADKY'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
 */
export interface AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    sefName: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementPriceInfoDto}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    price: AdvertisementDetailAdvertisementPriceInfoDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    locationName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    isPremium: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementCategoryDto}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    category: AdvertisementDetailAdvertisementCategoryDto;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    area?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    realEstateState?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    advertiserType: AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDtoAdvertiserTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    isFavorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    transaction: string;
    /**
     * 
     * @type {Array<AdvertisementDetailMediaVideoDto>}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    videos: Array<AdvertisementDetailMediaVideoDto>;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    devProjectId?: string;
    /**
     * 
     * @type {AdvertisementDetailListingAdvertisementLocationDto}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto
     */
    location: AdvertisementDetailListingAdvertisementLocationDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDtoAdvertiserTypeEnum {
    Agent = 'AGENT',
    PrivatePerson = 'PRIVATE_PERSON'
}

/**
 * 
 * @export
 * @interface AdvertisementDetailOtherAdvertiserAdvertisementDto
 */
export interface AdvertisementDetailOtherAdvertiserAdvertisementDto {
    /**
     * 
     * @type {Array<AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto>}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementDto
     */
    advertisements: Array<AdvertisementDetailOtherAdvertiserAdvertisementAdvertisementDto>;
    /**
     * 
     * @type {AdvertisementDetailListingAdvertisementsAdvertiserDto}
     * @memberof AdvertisementDetailOtherAdvertiserAdvertisementDto
     */
    advertiser: AdvertisementDetailListingAdvertisementsAdvertiserDto;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailParametersAttributeDto
 */
export interface AdvertisementDetailParametersAttributeDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersAttributeDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersAttributeDto
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailParametersAttributeDto
     */
    isImportant: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailParametersDto
 */
export interface AdvertisementDetailParametersDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersDto
     */
    title: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementPriceInfoDto}
     * @memberof AdvertisementDetailParametersDto
     */
    price: AdvertisementDetailAdvertisementPriceInfoDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersDto
     */
    transaction: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementCategoryDto}
     * @memberof AdvertisementDetailParametersDto
     */
    category: AdvertisementDetailAdvertisementCategoryDto;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailParametersDto
     */
    area?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersDto
     */
    realEstateState?: string;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailParametersDto
     */
    floor?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailParametersDto
     */
    numberOfFloors?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailParametersDto
     */
    hasElevator: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementDetailParametersDto
     */
    yearOfConstruction?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersDto
     */
    construction?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailParametersDto
     */
    energyRatingCertificate?: string;
    /**
     * 
     * @type {Array<AdvertisementDetailParametersAttributeDto>}
     * @memberof AdvertisementDetailParametersDto
     */
    attributes: Array<AdvertisementDetailParametersAttributeDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailSimilarAdvertisementsAdvertisementDto
 */
export interface AdvertisementDetailSimilarAdvertisementsAdvertisementDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    transaction: string;
    /**
     * 
     * @type {Array<AdvertisementDetailMediaVideoDto>}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    videos: Array<AdvertisementDetailMediaVideoDto>;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    realEstateState?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    devProjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    sefName: string;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementPriceInfoDto}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    price: AdvertisementDetailAdvertisementPriceInfoDto;
    /**
     * 
     * @type {AdvertisementDetailListingAdvertisementLocationDto}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    location: AdvertisementDetailListingAdvertisementLocationDto;
    /**
     * 
     * @type {AdvertisementDetailAdvertisementCategoryDto}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    category: AdvertisementDetailAdvertisementCategoryDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    locationName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    isFavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    isPremium: boolean;
    /**
     * 
     * @type {AdvertisementDetailListingAdvertisementsAdvertiserDto}
     * @memberof AdvertisementDetailSimilarAdvertisementsAdvertisementDto
     */
    advertiser: AdvertisementDetailListingAdvertisementsAdvertiserDto;
}
/**
 * 
 * @export
 * @interface AdvertisementDetailSimilarAdvertisementsDto
 */
export interface AdvertisementDetailSimilarAdvertisementsDto {
    /**
     * 
     * @type {Array<AdvertisementDetailSimilarAdvertisementsAdvertisementDto>}
     * @memberof AdvertisementDetailSimilarAdvertisementsDto
     */
    advertisements: Array<AdvertisementDetailSimilarAdvertisementsAdvertisementDto>;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementDetailSimilarAdvertisementsDto
     */
    title: string;
    /**
     * 
     * @type {AdvertisementListingFilterDataDto}
     * @memberof AdvertisementDetailSimilarAdvertisementsDto
     */
    filter: AdvertisementListingFilterDataDto;
}
/**
 * 
 * @export
 * @interface AdvertisementListBreadcrumbDto
 */
export interface AdvertisementListBreadcrumbDto {
    /**
     * 
     * @type {Array<AdvertisementBreadcrumbItemDto>}
     * @memberof AdvertisementListBreadcrumbDto
     */
    parts: Array<AdvertisementBreadcrumbItemDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementListResultAdvertisementDevProjectDto
 */
export interface AdvertisementListResultAdvertisementDevProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementDevProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementDevProjectDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementDevProjectDto
     */
    slugName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementDevProjectDto
     */
    externalId?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListResultAdvertisementParametersCategoryDto
 */
export interface AdvertisementListResultAdvertisementParametersCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementParametersCategoryDto
     */
    mainValue: AdvertisementListResultAdvertisementParametersCategoryDtoMainValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementParametersCategoryDto
     */
    subValue?: AdvertisementListResultAdvertisementParametersCategoryDtoSubValueEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListResultAdvertisementParametersCategoryDtoMainValueEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}
/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListResultAdvertisementParametersCategoryDtoSubValueEnum {
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface AdvertisementListResultAdvertisementParametersDto
 */
export interface AdvertisementListResultAdvertisementParametersDto {
    /**
     * 
     * @type {AdvertisementListResultAdvertisementParametersCategoryDto}
     * @memberof AdvertisementListResultAdvertisementParametersDto
     */
    category: AdvertisementListResultAdvertisementParametersCategoryDto;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListResultAdvertisementParametersDto
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListResultAdvertisementParametersDto
     */
    totalRoomsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementParametersDto
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultAdvertisementParametersDto
     */
    realEstateState?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListResultAdvertiserLocationDto
 */
export interface AdvertisementListResultAdvertiserLocationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvertisementListResultAdvertiserLocationDto
     */
    parts: Array<string>;
}
/**
 * 
 * @export
 * @interface AdvertisementListResultLocationDto
 */
export interface AdvertisementListResultLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultLocationDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultLocationDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultLocationDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListResultLocationDto
     */
    city?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListResultMediaDto
 */
export interface AdvertisementListResultMediaDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvertisementListResultMediaDto
     */
    videoUrls: Array<string>;
}
/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto
 */
export interface AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto
     */
    totalCount: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto
     */
    category: AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}

/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDevProjectDto
 */
export interface AdvertisementListingDevProjectsDevProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    detailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {AdvertisementListingPriceInfoDto}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    priceInfo: AdvertisementListingPriceInfoDto;
    /**
     * 
     * @type {Array<AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto>}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    advertisementsInfo: Array<AdvertisementListingDevProjectsDevProjectAdvertisementsInfoDto>;
    /**
     * 
     * @type {AdvertisementListingDevProjectsDevProjectFlagsDto}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    flags: AdvertisementListingDevProjectsDevProjectFlagsDto;
    /**
     * 
     * @type {AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    locationInfo: AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto;
    /**
     * 
     * @type {AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    advertiser: AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDto
     */
    realEstateState: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto
 */
export interface AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoAdvertiserDto
     */
    agencyName: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
 */
export interface AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    locationString?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingDevProjectsDevProjectDtoLocationInfoDto
     */
    country: string;
}
/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDevProjectFlagsDto
 */
export interface AdvertisementListingDevProjectsDevProjectFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementListingDevProjectsDevProjectFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementListingDevProjectsDevProjectFlagsDto
     */
    isPremium: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementListingDevProjectsDto
 */
export interface AdvertisementListingDevProjectsDto {
    /**
     * 
     * @type {Array<AdvertisementListingDevProjectsDevProjectDto>}
     * @memberof AdvertisementListingDevProjectsDto
     */
    projects: Array<AdvertisementListingDevProjectsDevProjectDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementListingFilterDataDto
 */
export interface AdvertisementListingFilterDataDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingFilterDataDto
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingFilterDataDto
     */
    transaction?: AdvertisementListingFilterDataDtoTransactionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvertisementListingFilterDataDto
     */
    categories?: Array<AdvertisementListingFilterDataDtoCategoriesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterDataDto
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterDataDto
     */
    priceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterDataDto
     */
    areaFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterDataDto
     */
    areaTo?: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingFilterDataDto
     */
    fulltext?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementListingFilterDataDto
     */
    onlyNewbuilding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementListingFilterDataDto
     */
    onlyWithVideo?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingFilterDataDtoTransactionEnum {
    Purchase = 'PURCHASE',
    Hireout = 'HIREOUT',
    Rent = 'RENT',
    Exchange = 'EXCHANGE',
    Auction = 'AUCTION',
    Sale = 'SALE'
}
/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingFilterDataDtoCategoriesEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS',
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface AdvertisementListingFilterDto
 */
export interface AdvertisementListingFilterDto {
    /**
     * 
     * @type {AdvertisementListingFilterPaginationDto}
     * @memberof AdvertisementListingFilterDto
     */
    pagination: AdvertisementListingFilterPaginationDto;
    /**
     * 
     * @type {AdvertisementListingFilterDataDto}
     * @memberof AdvertisementListingFilterDto
     */
    data: AdvertisementListingFilterDataDto;
}
/**
 * 
 * @export
 * @interface AdvertisementListingFilterPaginationDto
 */
export interface AdvertisementListingFilterPaginationDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterPaginationDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingFilterPaginationDto
     */
    skip: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingFilterPaginationDto
     */
    order: AdvertisementListingFilterPaginationDtoOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingFilterPaginationDtoOrderEnum {
    Default = 'DEFAULT',
    Newest = 'NEWEST',
    PriceAsc = 'PRICE_ASC',
    PriceDesc = 'PRICE_DESC'
}

/**
 * 
 * @export
 * @interface AdvertisementListingPriceInfoDto
 */
export interface AdvertisementListingPriceInfoDto {
    /**
     * 
     * @type {AdvertisementListingPriceInfoDtoPriceDto}
     * @memberof AdvertisementListingPriceInfoDto
     */
    pricePrimary?: AdvertisementListingPriceInfoDtoPriceDto;
    /**
     * 
     * @type {AdvertisementListingPriceInfoDtoPriceDto}
     * @memberof AdvertisementListingPriceInfoDto
     */
    priceSecondary?: AdvertisementListingPriceInfoDtoPriceDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingPriceInfoDto
     */
    priceOption?: AdvertisementListingPriceInfoDtoPriceOptionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingPriceInfoDtoPriceOptionEnum {
    PriceByAgreement = 'PRICE_BY_AGREEMENT',
    InfoInRealEstateOffice = 'INFO_IN_REAL_ESTATE_OFFICE'
}

/**
 * 
 * @export
 * @interface AdvertisementListingPriceInfoDtoPriceDto
 */
export interface AdvertisementListingPriceInfoDtoPriceDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementListingPriceInfoDtoPriceDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingPriceInfoDtoPriceDto
     */
    unit: AdvertisementListingPriceInfoDtoPriceDtoUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementListingPriceInfoDtoPriceDto
     */
    currency: AdvertisementListingPriceInfoDtoPriceDtoCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingPriceInfoDtoPriceDtoUnitEnum {
    Currency = 'CURRENCY',
    CurrencyPerM2 = 'CURRENCY_PER_M2',
    CurrencyPerMonth = 'CURRENCY_PER_MONTH',
    CurrencyPerYear = 'CURRENCY_PER_YEAR',
    CurrencyPerM2PerMonth = 'CURRENCY_PER_M2_PER_MONTH',
    CurrencyPerM2PerYear = 'CURRENCY_PER_M2_PER_YEAR',
    CurrencyPerDay = 'CURRENCY_PER_DAY'
}
/**
    * @export
    * @enum {string}
    */
export enum AdvertisementListingPriceInfoDtoPriceDtoCurrencyEnum {
    Eur = 'EUR',
    Czk = 'CZK',
    Gbp = 'GBP',
    Usd = 'USD',
    Pln = 'PLN',
    Huf = 'HUF',
    Ron = 'RON',
    Rsd = 'RSD'
}

/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapFilterDto
 */
export interface AdvertisementProjectsSearchMapFilterDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    transaction?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    category?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    state?: number;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterPriceRangeDto}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    price?: AdvertisementProjectsSearchMapFilterPriceRangeDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMaptFilterViewportDto}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    viewport?: AdvertisementProjectsSearchMaptFilterViewportDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    fulltext?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementProjectsSearchMapFilterDto
     */
    allProjects?: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapFilterLocationDto
 */
export interface AdvertisementProjectsSearchMapFilterLocationDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterLocationDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterLocationDto
     */
    longitude: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapFilterPriceRangeDto
 */
export interface AdvertisementProjectsSearchMapFilterPriceRangeDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterPriceRangeDto
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapFilterPriceRangeDto
     */
    to?: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectDto
 */
export interface AdvertisementProjectsSearchMapProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    previewPhoto: string;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectPricesDto}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    prices: AdvertisementProjectsSearchMapProjectPricesDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    photos: Array<string>;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectRangeDto}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    areaRange: AdvertisementProjectsSearchMapProjectRangeDto;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    externalId: number;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectServicesDto}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    services: AdvertisementProjectsSearchMapProjectServicesDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    categoryName: string;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectLocationInfoDto}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    location: AdvertisementProjectsSearchMapProjectLocationInfoDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectUnitCountsDto}
     * @memberof AdvertisementProjectsSearchMapProjectDto
     */
    unitCounts: AdvertisementProjectsSearchMapProjectUnitCountsDto;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectLocationDto
 */
export interface AdvertisementProjectsSearchMapProjectLocationDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectLocationDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectLocationDto
     */
    longitude: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectLocationInfoDto
 */
export interface AdvertisementProjectsSearchMapProjectLocationInfoDto {
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectLocationDto}
     * @memberof AdvertisementProjectsSearchMapProjectLocationInfoDto
     */
    gps: AdvertisementProjectsSearchMapProjectLocationDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectLocationInfoDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectLocationInfoDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementProjectsSearchMapProjectLocationInfoDto
     */
    district: string;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectPriceDto
 */
export interface AdvertisementProjectsSearchMapProjectPriceDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectPriceDto
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectPriceDto
     */
    to: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectPriceDto
     */
    unitId: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectPricesDto
 */
export interface AdvertisementProjectsSearchMapProjectPricesDto {
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectPriceDto}
     * @memberof AdvertisementProjectsSearchMapProjectPricesDto
     */
    total: AdvertisementProjectsSearchMapProjectPriceDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapProjectPriceDto}
     * @memberof AdvertisementProjectsSearchMapProjectPricesDto
     */
    unit: AdvertisementProjectsSearchMapProjectPriceDto;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectPricesDto
     */
    options: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectRangeDto
 */
export interface AdvertisementProjectsSearchMapProjectRangeDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectRangeDto
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectRangeDto
     */
    to: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectServicesDto
 */
export interface AdvertisementProjectsSearchMapProjectServicesDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementProjectsSearchMapProjectServicesDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementProjectsSearchMapProjectServicesDto
     */
    isPremium: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementProjectsSearchMapProjectServicesDto
     */
    isTeasing: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMapProjectUnitCountsDto
 */
export interface AdvertisementProjectsSearchMapProjectUnitCountsDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectUnitCountsDto
     */
    filtered: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementProjectsSearchMapProjectUnitCountsDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface AdvertisementProjectsSearchMaptFilterViewportDto
 */
export interface AdvertisementProjectsSearchMaptFilterViewportDto {
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterLocationDto}
     * @memberof AdvertisementProjectsSearchMaptFilterViewportDto
     */
    southWest: AdvertisementProjectsSearchMapFilterLocationDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterLocationDto}
     * @memberof AdvertisementProjectsSearchMaptFilterViewportDto
     */
    southEast: AdvertisementProjectsSearchMapFilterLocationDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterLocationDto}
     * @memberof AdvertisementProjectsSearchMaptFilterViewportDto
     */
    northWest: AdvertisementProjectsSearchMapFilterLocationDto;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterLocationDto}
     * @memberof AdvertisementProjectsSearchMaptFilterViewportDto
     */
    northEast: AdvertisementProjectsSearchMapFilterLocationDto;
}
/**
 * 
 * @export
 * @interface AdvertisementRelationDto
 */
export interface AdvertisementRelationDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementRelationDto
     */
    oldId: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementRelationDto
     */
    newId: string;
}
/**
 * 
 * @export
 * @interface AdvertisementReportDto
 */
export interface AdvertisementReportDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementReportDto
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementReportDto
     */
    type: AdvertisementReportDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementReportDtoTypeEnum {
    Wrong = 'WRONG',
    Other = 'OTHER',
    OutOfDate = 'OUT_OF_DATE',
    WrongCategory = 'WRONG_CATEGORY'
}

/**
 * 
 * @export
 * @interface AdvertisementSearchAgentSaveSearchAgentDto
 */
export interface AdvertisementSearchAgentSaveSearchAgentDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementSearchAgentSaveSearchAgentDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementSearchAgentSaveSearchAgentDto
     */
    searchAgentname: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementSearchAgentSaveSearchAgentDto
     */
    frequency: AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum;
    /**
     * 
     * @type {AdvertisementListingFilterDataDto}
     * @memberof AdvertisementSearchAgentSaveSearchAgentDto
     */
    filterData: AdvertisementListingFilterDataDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum {
    Immediately = 'IMMEDIATELY',
    EveryDay = 'EVERY_DAY',
    EveryWeek = 'EVERY_WEEK',
    Disabled = 'DISABLED'
}

/**
 * 
 * @export
 * @interface AdvertisementsListDto
 */
export interface AdvertisementsListDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsListDto
     */
    totalCount: number;
    /**
     * 
     * @type {Array<AdvertisementsListResultDto>}
     * @memberof AdvertisementsListDto
     */
    results: Array<AdvertisementsListResultDto>;
    /**
     * 
     * @type {AdvertisementsListQuickLinkDto}
     * @memberof AdvertisementsListDto
     */
    quickLinks: AdvertisementsListQuickLinkDto;
    /**
     * 
     * @type {AdvertisementListBreadcrumbDto}
     * @memberof AdvertisementsListDto
     */
    breadcrumb: AdvertisementListBreadcrumbDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface AdvertisementsListQuickLinkDto
 */
export interface AdvertisementsListQuickLinkDto {
    /**
     * 
     * @type {Array<AdvertisementBreadcrumbItemDto>}
     * @memberof AdvertisementsListQuickLinkDto
     */
    parts: Array<AdvertisementBreadcrumbItemDto>;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultAdvertisementDto
 */
export interface AdvertisementsListResultAdvertisementDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    id: string;
    /**
     * 
     * @type {Array<AdvertisementsListResultAdvertisementPhotoDto>}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    photos: Array<AdvertisementsListResultAdvertisementPhotoDto>;
    /**
     * 
     * @type {AdvertisementsListResultAdvertisementFlagsDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    flags: AdvertisementsListResultAdvertisementFlagsDto;
    /**
     * 
     * @type {AdvertisementListResultAdvertisementDevProjectDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    devProject?: AdvertisementListResultAdvertisementDevProjectDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    sefName: string;
    /**
     * 
     * @type {AdvertisementListResultLocationDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    location: AdvertisementListResultLocationDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    description: string;
    /**
     * 
     * @type {AdvertisementListResultAdvertisementParametersDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    parameters: AdvertisementListResultAdvertisementParametersDto;
    /**
     * 
     * @type {AdvertisementListingPriceInfoDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    price: AdvertisementListingPriceInfoDto;
    /**
     * 
     * @type {AdvertisementListResultMediaDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    media: AdvertisementListResultMediaDto;
    /**
     * 
     * @type {AdvertisementsListResultAdvertiserDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    advertiser: AdvertisementsListResultAdvertiserDto;
    /**
     * 
     * @type {AdvertisementsListResultAdvertisementSearchResultsLinkDto}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    searchResultsLink: AdvertisementsListResultAdvertisementSearchResultsLinkDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultAdvertisementFlagsDto
 */
export interface AdvertisementsListResultAdvertisementFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertisementFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertisementFlagsDto
     */
    isPremium: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertisementFlagsDto
     */
    hasVideo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertisementFlagsDto
     */
    hasFloorPlan: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertisementFlagsDto
     */
    hasInspections3d: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultAdvertisementPhotoDto
 */
export interface AdvertisementsListResultAdvertisementPhotoDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertisementPhotoDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultAdvertisementSearchResultsLinkDto
 */
export interface AdvertisementsListResultAdvertisementSearchResultsLinkDto {
    /**
     * 
     * @type {AdvertisementBreadcrumbItemDto}
     * @memberof AdvertisementsListResultAdvertisementSearchResultsLinkDto
     */
    filterData: AdvertisementBreadcrumbItemDto;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultAdvertiserDto
 */
export interface AdvertisementsListResultAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    type: AdvertisementsListResultAdvertiserDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    webPage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    isPro: boolean;
    /**
     * 
     * @type {AdvertisementListResultAdvertiserLocationDto}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    location: AdvertisementListResultAdvertiserLocationDto;
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof AdvertisementsListResultAdvertiserDto
     */
    parent?: AdvertiserDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementsListResultAdvertiserDtoTypeEnum {
    Agent = 'AGENT',
    Agency = 'AGENCY',
    PrivatePerson = 'PRIVATE_PERSON'
}

/**
 * 
 * @export
 * @interface AdvertisementsListResultDevProjectAdvertisementsInfoDto
 */
export interface AdvertisementsListResultDevProjectAdvertisementsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsListResultDevProjectAdvertisementsInfoDto
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsListResultDevProjectAdvertisementsInfoDto
     */
    countMatchesTheFilter: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectAdvertisementsInfoDto
     */
    category: AdvertisementsListResultDevProjectAdvertisementsInfoDtoCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementsListResultDevProjectAdvertisementsInfoDtoCategoryEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}

/**
 * 
 * @export
 * @interface AdvertisementsListResultDevProjectDto
 */
export interface AdvertisementsListResultDevProjectDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    sefName: string;
    /**
     * 
     * @type {AdvertisementListResultAdvertisementParametersDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    parameters: AdvertisementListResultAdvertisementParametersDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    description: string;
    /**
     * 
     * @type {AdvertisementListResultLocationDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    location: AdvertisementListResultLocationDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {AdvertisementListingPriceInfoDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    price: AdvertisementListingPriceInfoDto;
    /**
     * 
     * @type {AdvertisementListResultMediaDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    media: AdvertisementListResultMediaDto;
    /**
     * 
     * @type {AdvertisementsListResultAdvertiserDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    advertiser: AdvertisementsListResultAdvertiserDto;
    /**
     * 
     * @type {Array<AdvertisementsListResultDevProjectAdvertisementsInfoDto>}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    advertisementsInfo: Array<AdvertisementsListResultDevProjectAdvertisementsInfoDto>;
    /**
     * 
     * @type {AdvertisementsListResultDevProjectFlagsDto}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    flags: AdvertisementsListResultDevProjectFlagsDto;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultDevProjectDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultDevProjectFlagsDto
 */
export interface AdvertisementsListResultDevProjectFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultDevProjectFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultDevProjectFlagsDto
     */
    isPremium: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultDevProjectFlagsDto
     */
    isProfi: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultDto
 */
export interface AdvertisementsListResultDto {
    /**
     * 
     * @type {AdvertisementsListResultAdvertisementDto}
     * @memberof AdvertisementsListResultDto
     */
    advertisement?: AdvertisementsListResultAdvertisementDto;
    /**
     * 
     * @type {AdvertisementsListResultDevProjectDto}
     * @memberof AdvertisementsListResultDto
     */
    devProject?: AdvertisementsListResultDevProjectDto;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultPriceInfPowerCostsDto
 */
export interface AdvertisementsListResultPriceInfPowerCostsDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsListResultPriceInfPowerCostsDto
     */
    value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsListResultPriceInfPowerCostsDto
     */
    areIncluded: boolean;
}
/**
 * 
 * @export
 * @interface AdvertisementsListResultPriceInfoPriceDto
 */
export interface AdvertisementsListResultPriceInfoPriceDto {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsListResultPriceInfoPriceDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultPriceInfoPriceDto
     */
    unit: AdvertisementsListResultPriceInfoPriceDtoUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsListResultPriceInfoPriceDto
     */
    currency: AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertisementsListResultPriceInfoPriceDtoUnitEnum {
    Currency = 'CURRENCY',
    CurrencyPerM2 = 'CURRENCY_PER_M2',
    CurrencyPerMonth = 'CURRENCY_PER_MONTH',
    CurrencyPerYear = 'CURRENCY_PER_YEAR',
    CurrencyPerM2PerMonth = 'CURRENCY_PER_M2_PER_MONTH',
    CurrencyPerM2PerYear = 'CURRENCY_PER_M2_PER_YEAR',
    CurrencyPerDay = 'CURRENCY_PER_DAY'
}
/**
    * @export
    * @enum {string}
    */
export enum AdvertisementsListResultPriceInfoPriceDtoCurrencyEnum {
    Eur = 'EUR',
    Czk = 'CZK',
    Gbp = 'GBP',
    Usd = 'USD',
    Pln = 'PLN',
    Huf = 'HUF',
    Ron = 'RON',
    Rsd = 'RSD'
}

/**
 * 
 * @export
 * @interface AdvertiserDto
 */
export interface AdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    type: AdvertiserDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    webPage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertiserDto
     */
    isPro: boolean;
    /**
     * 
     * @type {AdvertisementListResultAdvertiserLocationDto}
     * @memberof AdvertiserDto
     */
    location: AdvertisementListResultAdvertiserLocationDto;
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof AdvertiserDto
     */
    parent?: AdvertiserDto;
}

/**
    * @export
    * @enum {string}
    */
export enum AdvertiserDtoTypeEnum {
    Agent = 'AGENT',
    Agency = 'AGENCY',
    PrivatePerson = 'PRIVATE_PERSON'
}

/**
 * 
 * @export
 * @interface BulkAdvertisementsRelationDto
 */
export interface BulkAdvertisementsRelationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkAdvertisementsRelationDto
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface ComparisonAdvertisementsInComparisonDto
 */
export interface ComparisonAdvertisementsInComparisonDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ComparisonAdvertisementsInComparisonDto
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface DevProjectsExclusiveProjectAdvertisementsInfoDto
 */
export interface DevProjectsExclusiveProjectAdvertisementsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DevProjectsExclusiveProjectAdvertisementsInfoDto
     */
    totalCount: number;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectAdvertisementsInfoDto
     */
    category: DevProjectsExclusiveProjectAdvertisementsInfoDtoCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DevProjectsExclusiveProjectAdvertisementsInfoDtoCategoryEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}

/**
 * 
 * @export
 * @interface DevProjectsExclusiveProjectDtoo
 */
export interface DevProjectsExclusiveProjectDtoo {
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    photoUrl?: string;
    /**
     * 
     * @type {DevProjectsExclusiveProjectPriceInfoDto}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    priceInfo: DevProjectsExclusiveProjectPriceInfoDto;
    /**
     * 
     * @type {Array<AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto>}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    advertisementsInfo: Array<AdvertisementDetaiDevProjectsExclusiveProjectAdvertisementsInfoDto>;
    /**
     * 
     * @type {AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto}
     * @memberof DevProjectsExclusiveProjectDtoo
     */
    flags: AdvertisementDetaiDevProjectsExclusiveProjectFlagsDto;
}
/**
 * 
 * @export
 * @interface DevProjectsExclusiveProjectFlagsDto
 */
export interface DevProjectsExclusiveProjectFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof DevProjectsExclusiveProjectFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevProjectsExclusiveProjectFlagsDto
     */
    isPremium: boolean;
}
/**
 * 
 * @export
 * @interface DevProjectsExclusiveProjectPriceInfoDto
 */
export interface DevProjectsExclusiveProjectPriceInfoDto {
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof DevProjectsExclusiveProjectPriceInfoDto
     */
    pricePrimary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof DevProjectsExclusiveProjectPriceInfoDto
     */
    priceSecondary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {string}
     * @memberof DevProjectsExclusiveProjectPriceInfoDto
     */
    priceOption?: DevProjectsExclusiveProjectPriceInfoDtoPriceOptionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DevProjectsExclusiveProjectPriceInfoDtoPriceOptionEnum {
    PriceByAgreement = 'PRICE_BY_AGREEMENT',
    InfoInRealEstateOffice = 'INFO_IN_REAL_ESTATE_OFFICE'
}

/**
 * 
 * @export
 * @interface FavouriteAdvertisementsInFavouriteDto
 */
export interface FavouriteAdvertisementsInFavouriteDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FavouriteAdvertisementsInFavouriteDto
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface FilterUrlDataDtoResponse
 */
export interface FilterUrlDataDtoResponse {
    /**
     * 
     * @type {SearchFilterDataLocationDto}
     * @memberof FilterUrlDataDtoResponse
     */
    location?: SearchFilterDataLocationDto;
    /**
     * 
     * @type {SearchFilterDataCategoryDto}
     * @memberof FilterUrlDataDtoResponse
     */
    category?: SearchFilterDataCategoryDto;
    /**
     * 
     * @type {SearchFilterDataTransactionDto}
     * @memberof FilterUrlDataDtoResponse
     */
    transaction?: SearchFilterDataTransactionDto;
}
/**
 * 
 * @export
 * @interface HistogramDataItemDto
 */
export interface HistogramDataItemDto {
    /**
     * 
     * @type {number}
     * @memberof HistogramDataItemDto
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramDataItemDto
     */
    to: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramDataItemDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramDataItemDto
     */
    percent: number;
}
/**
 * 
 * @export
 * @interface HistogramResponseDto
 */
export interface HistogramResponseDto {
    /**
     * 
     * @type {Array<HistogramDataItemDto>}
     * @memberof HistogramResponseDto
     */
    data: Array<HistogramDataItemDto>;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    defaultStart: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    defaultEnd: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    end: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    median: number;
    /**
     * 
     * @type {number}
     * @memberof HistogramResponseDto
     */
    avg: number;
}
/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectAdvertisementsInfoDto
 */
export interface HomePageDevProjectsDevProjectAdvertisementsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof HomePageDevProjectsDevProjectAdvertisementsInfoDto
     */
    totalCount: number;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectAdvertisementsInfoDto
     */
    category: HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum HomePageDevProjectsDevProjectAdvertisementsInfoDtoCategoryEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}

/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectAdvertiserDto
 */
export interface HomePageDevProjectsDevProjectAdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectAdvertiserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectAdvertiserDto
     */
    agencyName: string;
}
/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectDto
 */
export interface HomePageDevProjectsDevProjectDto {
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    detailUrl: string;
    /**
     * 
     * @type {HomePageDevProjectsDevProjectLocationDto}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    location: HomePageDevProjectsDevProjectLocationDto;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    realEstateState: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {HomePageDevProjectsDevProjectPriceInfoDto}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    priceInfo: HomePageDevProjectsDevProjectPriceInfoDto;
    /**
     * 
     * @type {HomePageDevProjectsDevProjectAdvertiserDto}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    advertiser: HomePageDevProjectsDevProjectAdvertiserDto;
    /**
     * 
     * @type {Array<HomePageDevProjectsDevProjectAdvertisementsInfoDto>}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    advertisementsInfo: Array<HomePageDevProjectsDevProjectAdvertisementsInfoDto>;
    /**
     * 
     * @type {HomePageDevProjectsDevProjectFlagsDto}
     * @memberof HomePageDevProjectsDevProjectDto
     */
    flags: HomePageDevProjectsDevProjectFlagsDto;
}
/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectFlagsDto
 */
export interface HomePageDevProjectsDevProjectFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof HomePageDevProjectsDevProjectFlagsDto
     */
    isTop: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HomePageDevProjectsDevProjectFlagsDto
     */
    isPremium: boolean;
}
/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectLocationDto
 */
export interface HomePageDevProjectsDevProjectLocationDto {
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectLocationDto
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface HomePageDevProjectsDevProjectPriceInfoDto
 */
export interface HomePageDevProjectsDevProjectPriceInfoDto {
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof HomePageDevProjectsDevProjectPriceInfoDto
     */
    pricePrimary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {AdvertisementsListResultPriceInfoPriceDto}
     * @memberof HomePageDevProjectsDevProjectPriceInfoDto
     */
    priceSecondary?: AdvertisementsListResultPriceInfoPriceDto;
    /**
     * 
     * @type {string}
     * @memberof HomePageDevProjectsDevProjectPriceInfoDto
     */
    priceOption?: HomePageDevProjectsDevProjectPriceInfoDtoPriceOptionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum HomePageDevProjectsDevProjectPriceInfoDtoPriceOptionEnum {
    PriceByAgreement = 'PRICE_BY_AGREEMENT',
    InfoInRealEstateOffice = 'INFO_IN_REAL_ESTATE_OFFICE'
}

/**
 * 
 * @export
 * @interface HomePageDevProjectsDto
 */
export interface HomePageDevProjectsDto {
    /**
     * 
     * @type {Array<HomePageDevProjectsDevProjectDto>}
     * @memberof HomePageDevProjectsDto
     */
    projects: Array<HomePageDevProjectsDevProjectDto>;
}
/**
 * 
 * @export
 * @interface HttpExceptionResponseDto
 */
export interface HttpExceptionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof HttpExceptionResponseDto
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof HttpExceptionResponseDto
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof HttpExceptionResponseDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof HttpExceptionResponseDto
     */
    path: string;
}
/**
 * 
 * @export
 * @interface ListingSearchUrlFilterRequestDto
 */
export interface ListingSearchUrlFilterRequestDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    categories?: Array<ListingSearchUrlFilterRequestDtoCategoriesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    locationId?: string;
    /**
     * 
     * @type {number}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    transactionId?: ListingSearchUrlFilterRequestDtoTransactionIdEnum;
    /**
     * 
     * @type {number}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    priceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    areaFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    areaTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    fulltext?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    onlyNewbuilding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    onlyWithVideo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListingSearchUrlFilterRequestDto
     */
    order?: ListingSearchUrlFilterRequestDtoOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ListingSearchUrlFilterRequestDtoCategoriesEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_30746 = 30746,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_44 = 44,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_45 = 45,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_64 = 64,
    NUMBER_30747 = 30747,
    NUMBER_46 = 46,
    NUMBER_57 = 57,
    NUMBER_47 = 47,
    NUMBER_58 = 58,
    NUMBER_48 = 48,
    NUMBER_59 = 59,
    NUMBER_74 = 74,
    NUMBER_78 = 78,
    NUMBER_49 = 49,
    NUMBER_61 = 61,
    NUMBER_50 = 50,
    NUMBER_62 = 62,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_55 = 55,
    NUMBER_56 = 56,
    NUMBER_60 = 60,
    NUMBER_63 = 63,
    NUMBER_66 = 66,
    NUMBER_67 = 67,
    NUMBER_68 = 68,
    NUMBER_69 = 69,
    NUMBER_70 = 70,
    NUMBER_71 = 71,
    NUMBER_72 = 72,
    NUMBER_73 = 73,
    NUMBER_24719 = 24719,
    NUMBER_65 = 65
}
/**
    * @export
    * @enum {string}
    */
export enum ListingSearchUrlFilterRequestDtoTransactionIdEnum {
    NUMBER_122 = 122,
    NUMBER_123 = 123,
    NUMBER_124 = 124,
    NUMBER_125 = 125,
    NUMBER_126 = 126,
    NUMBER_127 = 127
}
/**
    * @export
    * @enum {string}
    */
export enum ListingSearchUrlFilterRequestDtoOrderEnum {
    Default = 'DEFAULT',
    Newest = 'NEWEST',
    PriceAsc = 'PRICE_ASC',
    PriceDesc = 'PRICE_DESC'
}

/**
 * 
 * @export
 * @interface ListingSearchUrlResponseDto
 */
export interface ListingSearchUrlResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ListingSearchUrlResponseDto
     */
    searchUrl: string;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    type: LocationDtoTypeEnum;
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationDto
     */
    parent?: LocationDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationDtoTypeEnum {
    Country = 'COUNTRY',
    County = 'COUNTY',
    District = 'DISTRICT',
    City = 'CITY',
    CityPart = 'CITY_PART',
    Street = 'STREET',
    StreetNumber = 'STREET_NUMBER'
}

/**
 * 
 * @export
 * @interface LocationResponseDto
 */
export interface LocationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    sefName: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    type: LocationResponseDtoTypeEnum;
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationResponseDto
     */
    parent?: LocationDto;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationResponseDtoTypeEnum {
    Country = 'COUNTRY',
    County = 'COUNTY',
    District = 'DISTRICT',
    City = 'CITY',
    CityPart = 'CITY_PART',
    Street = 'STREET',
    StreetNumber = 'STREET_NUMBER'
}

/**
 * 
 * @export
 * @interface LocationsByIdsRequestDto
 */
export interface LocationsByIdsRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationsByIdsRequestDto
     */
    locationIds: Array<string>;
}
/**
 * 
 * @export
 * @interface LocationsBySefNamesRequestDto
 */
export interface LocationsBySefNamesRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationsBySefNamesRequestDto
     */
    sefNames: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationEmailAboutUsPageDto
 */
export interface NotificationEmailAboutUsPageDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailAboutUsPageDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailAboutUsPageDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailAboutUsPageDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailAboutUsPageDto
     */
    text: string;
}
/**
 * 
 * @export
 * @interface NotificationEmailFirmRegistrationPageAgentDto
 */
export interface NotificationEmailFirmRegistrationPageAgentDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageAgentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageAgentDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageAgentDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageAgentDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageAgentDto
     */
    loginEmail: string;
}
/**
 * 
 * @export
 * @interface NotificationEmailFirmRegistrationPageContactsDto
 */
export interface NotificationEmailFirmRegistrationPageContactsDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageContactsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageContactsDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageContactsDto
     */
    webUrl?: string;
}
/**
 * 
 * @export
 * @interface NotificationEmailFirmRegistrationPageDto
 */
export interface NotificationEmailFirmRegistrationPageDto {
    /**
     * 
     * @type {NotificationEmailFirmRegistrationPageAgentDto}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    agent: NotificationEmailFirmRegistrationPageAgentDto;
    /**
     * 
     * @type {NotificationEmailFirmRegistrationPageFirmDto}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    firm: NotificationEmailFirmRegistrationPageFirmDto;
    /**
     * 
     * @type {NotificationEmailFirmRegistrationPageFirmDto}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    billingData?: NotificationEmailFirmRegistrationPageFirmDto;
    /**
     * 
     * @type {NotificationEmailFirmRegistrationPageContactsDto}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    contacts: NotificationEmailFirmRegistrationPageContactsDto;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    agreeToTerms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationEmailFirmRegistrationPageDto
     */
    agreeToGDPR: boolean;
}
/**
 * 
 * @export
 * @interface NotificationEmailFirmRegistrationPageFirmDto
 */
export interface NotificationEmailFirmRegistrationPageFirmDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailFirmRegistrationPageFirmDto
     */
    vatId?: string;
}
/**
 * 
 * @export
 * @interface NotificationEmailInformationPageDto
 */
export interface NotificationEmailInformationPageDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailInformationPageDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailInformationPageDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailInformationPageDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailInformationPageDto
     */
    ico: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailInformationPageDto
     */
    text: string;
}
/**
 * 
 * @export
 * @interface NotificationEmailLeadgenerationPageDto
 */
export interface NotificationEmailLeadgenerationPageDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    district: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    agreeToTerms: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    agreeToGDPR: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    usableArea: string;
    /**
     * 
     * @type {AdvertisementProjectsSearchMapFilterLocationDto}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    location: AdvertisementProjectsSearchMapFilterLocationDto;
    /**
     * 
     * @type {string}
     * @memberof NotificationEmailLeadgenerationPageDto
     */
    utmCampaign?: string;
}
/**
 * 
 * @export
 * @interface NotificationResponseDto
 */
export interface NotificationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    type: NotificationResponseDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    style: NotificationResponseDtoStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationResponseDto
     */
    hasButton: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationResponseDto
     */
    canBeClosed: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    trigger: NotificationResponseDtoTriggerEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponseDto
     */
    section: NotificationResponseDtoSectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationResponseDtoTypeEnum {
    Bar = 'BAR',
    Modal = 'MODAL'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationResponseDtoStyleEnum {
    Warning = 'WARNING',
    Info = 'INFO',
    Update = 'UPDATE'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationResponseDtoTriggerEnum {
    WholeTime = 'WHOLE_TIME',
    FirstVisitOnly = 'FIRST_VISIT_ONLY'
}
/**
    * @export
    * @enum {string}
    */
export enum NotificationResponseDtoSectionEnum {
    All = 'ALL',
    Homepage = 'HOMEPAGE',
    Payments = 'PAYMENTS',
    SearchResults = 'SEARCH_RESULTS',
    Photos = 'PHOTOS',
    AdInsertion = 'AD_INSERTION'
}

/**
 * 
 * @export
 * @interface RealEstateAdressDto
 */
export interface RealEstateAdressDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdressDto
     */
    cityPart?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAdvertisementListingAdvertisementDto
 */
export interface RealEstateAdvertisementListingAdvertisementDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    description: string;
    /**
     * 
     * @type {Array<RealEstateAgencyDetailAdvertisementVideoDto>}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    videos: Array<RealEstateAgencyDetailAdvertisementVideoDto>;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    floorPlanUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    isDevProject: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    area?: number;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementOwnerDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    ownerInfo: RealEstateAgencyDetailAdvertisementOwnerDto;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementAgencyDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    agencyInfo: RealEstateAgencyDetailAdvertisementAgencyDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    realEstateState?: RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementAddressDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    address: RealEstateAgencyDetailAdvertisementAddressDto;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementPhotosDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    photos: RealEstateAgencyDetailAdvertisementPhotosDto;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementCategoriesDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    categories: RealEstateAgencyDetailAdvertisementCategoriesDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    transaction: RealEstateAdvertisementListingAdvertisementDtoTransactionEnum;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementPriceInfoDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    priceInfo: RealEstateAgencyDetailAdvertisementPriceInfoDto;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementPackagesDto}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    packages: RealEstateAgencyDetailAdvertisementPackagesDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAdvertisementListingAdvertisementDto
     */
    externalId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAdvertisementListingAdvertisementDtoRealEstateStateEnum {
    Newbuilding = 'NEWBUILDING',
    PartialReconstruction = 'PARTIAL_RECONSTRUCTION',
    CompleteReconstruction = 'COMPLETE_RECONSTRUCTION',
    OriginalCondition = 'ORIGINAL_CONDITION',
    UnderConstruction = 'UNDER_CONSTRUCTION',
    DevelopProject = 'DEVELOP_PROJECT',
    IntendedToDemolition = 'INTENDED_TO_DEMOLITION',
    Preparation = 'PREPARATION',
    Finished = 'FINISHED',
    SoldOut = 'SOLD_OUT'
}
/**
    * @export
    * @enum {string}
    */
export enum RealEstateAdvertisementListingAdvertisementDtoTransactionEnum {
    Purchase = 'PURCHASE',
    Hireout = 'HIREOUT',
    Rent = 'RENT',
    Exchange = 'EXCHANGE',
    Auction = 'AUCTION',
    Sale = 'SALE'
}

/**
 * 
 * @export
 * @interface RealEstateAdvertisementListingResponseDto
 */
export interface RealEstateAdvertisementListingResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAdvertisementListingResponseDto
     */
    isAgencyProfi: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementListingResponseDto
     */
    numberOfTotalAdvertisements: number;
    /**
     * 
     * @type {Array<RealEstateAdvertisementListingAdvertisementDto>}
     * @memberof RealEstateAdvertisementListingResponseDto
     */
    advertisements: Array<RealEstateAdvertisementListingAdvertisementDto>;
}
/**
 * 
 * @export
 * @interface RealEstateAdvertisementsCountInfoStatsDto
 */
export interface RealEstateAdvertisementsCountInfoStatsDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementsCountInfoStatsDto
     */
    numberOfTotalForSale?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementsCountInfoStatsDto
     */
    numberOfTotalForRent?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementsCountInfoStatsDto
     */
    averageSellingPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementsCountInfoStatsDto
     */
    averageRentalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAdvertisementsCountInfoStatsDto
     */
    totalCount?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgenciesListingRelatedDto
 */
export interface RealEstateAgenciesListingRelatedDto {
    /**
     * Location IDs separated by comma
     * @type {Array<string>}
     * @memberof RealEstateAgenciesListingRelatedDto
     */
    locationIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgenciesListingRelatedDto
     */
    allOtherLocationsExceptTheSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgenciesListingRelatedDto
     */
    searchNameTerm?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyAgencyLocationDto
 */
export interface RealEstateAgencyAgencyLocationDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgencyLocationDto
     */
    locationId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgencyLocationDto
     */
    locationName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgencyLocationDto
     */
    locationSef: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyAgencyLocationDto
     */
    isParent?: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyAgentContactsDto
 */
export interface RealEstateAgencyAgentContactsDto {
    /**
     * 
     * @type {RealEstatePhoneNumbersDetailPageDto}
     * @memberof RealEstateAgencyAgentContactsDto
     */
    phone?: RealEstatePhoneNumbersDetailPageDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgentContactsDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgentContactsDto
     */
    web?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgentContactsDto
     */
    whatsapp?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyAgentContactsDto
     */
    messenger?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementAddressDto
 */
export interface RealEstateAgencyDetailAdvertisementAddressDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    cityPart?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    streetNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailAdvertisementAddressDto
     */
    isForeign: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementAgencyDto
 */
export interface RealEstateAgencyDetailAdvertisementAgencyDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementAgencyDto
     */
    agencyName: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementCategoriesDto
 */
export interface RealEstateAgencyDetailAdvertisementCategoriesDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementCategoriesDto
     */
    mainCategory: RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementCategoriesDto
     */
    subCategory: RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementCategoriesDtoMainCategoryEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS'
}
/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementCategoriesDtoSubCategoryEnum {
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementListingFilterDto
 */
export interface RealEstateAgencyDetailAdvertisementListingFilterDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAdvertisementListingFilterDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAdvertisementListingFilterDto
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementListingFilterDto
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementOwnerDto
 */
export interface RealEstateAgencyDetailAdvertisementOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementOwnerDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailAdvertisementOwnerDto
     */
    isPro?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementOwnerDto
     */
    ownerType: RealEstateAgencyDetailAdvertisementOwnerDtoOwnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementOwnerDto
     */
    ownerName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementOwnerDtoOwnerTypeEnum {
    User = 'USER',
    Agency = 'AGENCY',
    Branch = 'BRANCH'
}

/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementPackagesDto
 */
export interface RealEstateAgencyDetailAdvertisementPackagesDto {
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailAdvertisementPackagesDto
     */
    isTop?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailAdvertisementPackagesDto
     */
    isPremium?: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementPhotosDto
 */
export interface RealEstateAgencyDetailAdvertisementPhotosDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementPhotosDto
     */
    previewPhotoUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealEstateAgencyDetailAdvertisementPhotosDto
     */
    thumbPhotosUrl: Array<string>;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementPriceInfoDto
 */
export interface RealEstateAgencyDetailAdvertisementPriceInfoDto {
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementPriceInfoPriceDto}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoDto
     */
    pricePrimary?: RealEstateAgencyDetailAdvertisementPriceInfoPriceDto;
    /**
     * 
     * @type {RealEstateAgencyDetailAdvertisementPriceInfoPriceDto}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoDto
     */
    priceSecondary?: RealEstateAgencyDetailAdvertisementPriceInfoPriceDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoDto
     */
    priceOption?: RealEstateAgencyDetailAdvertisementPriceInfoDtoPriceOptionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementPriceInfoDtoPriceOptionEnum {
    PriceByAgreement = 'PRICE_BY_AGREEMENT',
    InfoInRealEstateOffice = 'INFO_IN_REAL_ESTATE_OFFICE'
}

/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementPriceInfoPriceDto
 */
export interface RealEstateAgencyDetailAdvertisementPriceInfoPriceDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoPriceDto
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoPriceDto
     */
    unit: RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementPriceInfoPriceDto
     */
    currency: RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoUnitEnum {
    Currency = 'CURRENCY',
    CurrencyPerM2 = 'CURRENCY_PER_M2',
    CurrencyPerMonth = 'CURRENCY_PER_MONTH',
    CurrencyPerYear = 'CURRENCY_PER_YEAR',
    CurrencyPerM2PerMonth = 'CURRENCY_PER_M2_PER_MONTH',
    CurrencyPerM2PerYear = 'CURRENCY_PER_M2_PER_YEAR',
    CurrencyPerDay = 'CURRENCY_PER_DAY'
}
/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementPriceInfoPriceDtoCurrencyEnum {
    Eur = 'EUR',
    Czk = 'CZK',
    Gbp = 'GBP',
    Usd = 'USD',
    Pln = 'PLN',
    Huf = 'HUF',
    Ron = 'RON',
    Rsd = 'RSD'
}

/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAdvertisementVideoDto
 */
export interface RealEstateAgencyDetailAdvertisementVideoDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementVideoDto
     */
    type: RealEstateAgencyDetailAdvertisementVideoDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAdvertisementVideoDto
     */
    url: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RealEstateAgencyDetailAdvertisementVideoDtoTypeEnum {
    Youtube = 'YOUTUBE',
    Youtu = 'YOUTU',
    Pivo = 'PIVO',
    Videoobhliadky = 'VIDEOOBHLIADKY',
    Matterport = 'MATTERPORT',
    Revio = 'REVIO',
    Istaging = 'ISTAGING',
    Momento360 = 'MOMENTO360',
    Vieweet = 'VIEWEET',
    Kuula = 'KUULA',
    Tour = 'TOUR',
    Insta360 = 'INSTA360',
    Eyespy360 = 'EYESPY360',
    Lapentor = 'LAPENTOR',
    Cloudpano = 'CLOUDPANO',
    Cupix = 'CUPIX',
    Theasys = 'THEASYS',
    Ths = 'THS',
    Feelestate = 'FEELESTATE',
    Vimeo = 'VIMEO',
    Giraffe360 = 'GIRAFFE360',
    Drealitna = 'DREALITNA',
    Mollyinvr = 'MOLLYINVR',
    Grandpano = 'GRANDPANO'
}

/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAgentDto
 */
export interface RealEstateAgencyDetailAgentDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailAgentDto
     */
    isPro?: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAgentsDto
 */
export interface RealEstateAgencyDetailAgentsDto {
    /**
     * 
     * @type {Array<RealEstateAgencyDetailAgentDto>}
     * @memberof RealEstateAgencyDetailAgentsDto
     */
    agents: Array<RealEstateAgencyDetailAgentDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAgentsDto
     */
    numberOfTotalAgents: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailAgentsFilterDto
 */
export interface RealEstateAgencyDetailAgentsFilterDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAgentsFilterDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailAgentsFilterDto
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailBranchDto
 */
export interface RealEstateAgencyDetailBranchDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    parentSlugName?: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    address: RealEstateAdressDto;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailBranchDto
     */
    numberOfTotalActiveAdvertisements: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailBranchesDto
 */
export interface RealEstateAgencyDetailBranchesDto {
    /**
     * 
     * @type {Array<RealEstateAgencyDetailBranchDto>}
     * @memberof RealEstateAgencyDetailBranchesDto
     */
    branches: Array<RealEstateAgencyDetailBranchDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailBranchesDto
     */
    numberOfTotalBranches: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailBranchesFilterDto
 */
export interface RealEstateAgencyDetailBranchesFilterDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailBranchesFilterDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailBranchesFilterDto
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailDto
 */
export interface RealEstateAgencyDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailDto
     */
    isProfi: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyDetailDto
     */
    isCentralBranch: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    agencyName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    agencySlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    agencyLogoUrl?: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgencyDetailDto
     */
    agencyAddress: RealEstateAdressDto;
    /**
     * 
     * @type {RealEstateAdvertisementsCountInfoStatsDto}
     * @memberof RealEstateAgencyDetailDto
     */
    advertisementsCountsInfo: RealEstateAdvertisementsCountInfoStatsDto;
    /**
     * 
     * @type {RealEstateAgencyAgentContactsDto}
     * @memberof RealEstateAgencyDetailDto
     */
    contacts: RealEstateAgencyAgentContactsDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    description: string;
    /**
     * 
     * @type {RealEstateLocationGpsDto}
     * @memberof RealEstateAgencyDetailDto
     */
    locationGps: RealEstateLocationGpsDto;
    /**
     * 
     * @type {RealEstateOpenHoursDto}
     * @memberof RealEstateAgencyDetailDto
     */
    openHours?: RealEstateOpenHoursDto;
    /**
     * 
     * @type {Array<RealEstateAgencyAgencyLocationDto>}
     * @memberof RealEstateAgencyDetailDto
     */
    locations?: Array<RealEstateAgencyAgencyLocationDto>;
    /**
     * 
     * @type {RealEstateAgencyDetailParentAgencyDto}
     * @memberof RealEstateAgencyDetailDto
     */
    parentAgency?: RealEstateAgencyDetailParentAgencyDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyDetailParentAgencyDto
 */
export interface RealEstateAgencyDetailParentAgencyDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailParentAgencyDto
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailParentAgencyDto
     */
    agencyName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyDetailParentAgencyDto
     */
    agencySlug: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyDetailParentAgencyDto
     */
    numberOfTotalActiveAdvertisements: number;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgencyDetailParentAgencyDto
     */
    agencyAddress: RealEstateAdressDto;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingAdvertisementsCountInfoDto
 */
export interface RealEstateAgencyListingAdvertisementsCountInfoDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingAdvertisementsCountInfoDto
     */
    numberOfTotalForSale?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingAdvertisementsCountInfoDto
     */
    numberOfTotalForRent?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingAdvertisementsPhotosDto
 */
export interface RealEstateAgencyListingAdvertisementsPhotosDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RealEstateAgencyListingAdvertisementsPhotosDto
     */
    urls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAdvertisementsPhotosDto
     */
    advertisementId?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingAgencyAgentsInfoDto
 */
export interface RealEstateAgencyListingAgencyAgentsInfoDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingAgencyAgentsInfoDto
     */
    numberOfTotalActiveAgents: number;
    /**
     * 
     * @type {Array<RealEstateAgencyListingAgentsDto>}
     * @memberof RealEstateAgencyListingAgencyAgentsInfoDto
     */
    agents: Array<RealEstateAgencyListingAgentsDto>;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingAgencyDto
 */
export interface RealEstateAgencyListingAgencyDto {
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    isProfi: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    isCentralBranch: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agencyName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agencySlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    parentAgencySlugName?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agencyLogoUrl?: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agencyAddress: RealEstateAdressDto;
    /**
     * 
     * @type {RealEstateAgencyListingAgencyAgentsInfoDto}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    agentsInfo: RealEstateAgencyListingAgencyAgentsInfoDto;
    /**
     * 
     * @type {RealEstateListingAdvertisementsInfoDto}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    advertisementsInfo: RealEstateListingAdvertisementsInfoDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgencyDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingAgentsDto
 */
export interface RealEstateAgencyListingAgentsDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgentsDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingAgentsDto
     */
    previewPhotoUrl?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingFilterDto
 */
export interface RealEstateAgencyListingFilterDto {
    /**
     * Location IDs separated by comma
     * @type {string}
     * @memberof RealEstateAgencyListingFilterDto
     */
    locationIds?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingFilterDto
     */
    allOtherLocationsExceptTheSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingFilterDto
     */
    searchNameTerm?: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingFilterDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingFilterDto
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingLocationCollectionDto
 */
export interface RealEstateAgencyListingLocationCollectionDto {
    /**
     * 
     * @type {Array<RealEstateAgencyListingLocationDto>}
     * @memberof RealEstateAgencyListingLocationCollectionDto
     */
    items: Array<RealEstateAgencyListingLocationDto>;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingLocationDto
 */
export interface RealEstateAgencyListingLocationDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingLocationDto
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealEstateAgencyListingLocationDto
     */
    locationIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingLocationDto
     */
    nin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingLocationDto
     */
    isParent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingLocationDto
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyListingLocationDto
     */
    sefName: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyListingLocationDto
     */
    isForeign: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyListingResponseDto
 */
export interface RealEstateAgencyListingResponseDto {
    /**
     * 
     * @type {Array<RealEstateAgencyListingAgencyDto>}
     * @memberof RealEstateAgencyListingResponseDto
     */
    agencies: Array<RealEstateAgencyListingAgencyDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyListingResponseDto
     */
    numberOfTotalAgencies: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyProfiAgenciesFilterDto
 */
export interface RealEstateAgencyProfiAgenciesFilterDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyProfiAgenciesFilterDto
     */
    limit?: number;
    /**
     * Ignored Agency IDs separated by comma
     * @type {string}
     * @memberof RealEstateAgencyProfiAgenciesFilterDto
     */
    ignoredAgencyIds?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyProfiAgenciesResponseDto
 */
export interface RealEstateAgencyProfiAgenciesResponseDto {
    /**
     * 
     * @type {Array<RealEstateAgencyProfiAgencyDto>}
     * @memberof RealEstateAgencyProfiAgenciesResponseDto
     */
    agencies: Array<RealEstateAgencyProfiAgencyDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgencyProfiAgenciesResponseDto
     */
    numberOfTotalAgencies: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyProfiAgencyDto
 */
export interface RealEstateAgencyProfiAgencyDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    address: RealEstateAdressDto;
    /**
     * 
     * @type {Array<RealEstateAgencyListingAgentsDto>}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    agents: Array<RealEstateAgencyListingAgentsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgencyProfiAgencyDto
     */
    isCentralBranch: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateAgencyRedirectDto
 */
export interface RealEstateAgencyRedirectDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyRedirectDto
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgencyRedirectDto
     */
    agencySlugName: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgentDetailDto
 */
export interface RealEstateAgentDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgentDetailDto
     */
    isPro: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agentId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agentName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agentSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agentLogoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agencyName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agencyId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    agencyPreviewImageUrl: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgentDetailDto
     */
    agencyAddress: RealEstateAdressDto;
    /**
     * 
     * @type {RealEstateAdvertisementsCountInfoStatsDto}
     * @memberof RealEstateAgentDetailDto
     */
    advertisementsCountsInfo: RealEstateAdvertisementsCountInfoStatsDto;
    /**
     * 
     * @type {RealEstateAgencyAgentContactsDto}
     * @memberof RealEstateAgentDetailDto
     */
    contacts: RealEstateAgencyAgentContactsDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentDetailDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgentListingAgencyInfoDto
 */
export interface RealEstateAgentListingAgencyInfoDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgentListingAgencyInfoDto
     */
    agencyAddress: RealEstateAdressDto;
}
/**
 * 
 * @export
 * @interface RealEstateAgentListingAgentDto
 */
export interface RealEstateAgentListingAgentDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingAgentDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgentListingAgentDto
     */
    isPro?: boolean;
    /**
     * 
     * @type {RealEstateAgentListingAgencyInfoDto}
     * @memberof RealEstateAgentListingAgentDto
     */
    agency: RealEstateAgentListingAgencyInfoDto;
    /**
     * 
     * @type {RealEstateListingAdvertisementsInfoDto}
     * @memberof RealEstateAgentListingAgentDto
     */
    advertisementsInfo: RealEstateListingAdvertisementsInfoDto;
}
/**
 * 
 * @export
 * @interface RealEstateAgentListingFilterDto
 */
export interface RealEstateAgentListingFilterDto {
    /**
     * Location IDs separated by comma
     * @type {string}
     * @memberof RealEstateAgentListingFilterDto
     */
    locationIds?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgentListingFilterDto
     */
    allOtherLocationsExceptTheSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentListingFilterDto
     */
    searchNameTerm?: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgentListingFilterDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgentListingFilterDto
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgentProfiAgentDto
 */
export interface RealEstateAgentProfiAgentDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    userPreviewPhotoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    userSlugName: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgentProfiAgentDto
     */
    isProfi: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentProfiAgentDto
     */
    agencyName: string;
    /**
     * 
     * @type {RealEstateAdressDto}
     * @memberof RealEstateAgentProfiAgentDto
     */
    address: RealEstateAdressDto;
}
/**
 * 
 * @export
 * @interface RealEstateAgentProfiAgentsResponseDto
 */
export interface RealEstateAgentProfiAgentsResponseDto {
    /**
     * 
     * @type {Array<RealEstateAgentProfiAgentDto>}
     * @memberof RealEstateAgentProfiAgentsResponseDto
     */
    agents: Array<RealEstateAgentProfiAgentDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgentProfiAgentsResponseDto
     */
    numberOfTotalAgents: number;
}
/**
 * 
 * @export
 * @interface RealEstateAgentsListingRelatedDto
 */
export interface RealEstateAgentsListingRelatedDto {
    /**
     * Location IDs separated by comma
     * @type {Array<string>}
     * @memberof RealEstateAgentsListingRelatedDto
     */
    locationIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateAgentsListingRelatedDto
     */
    allOtherLocationsExceptTheSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateAgentsListingRelatedDto
     */
    searchNameTerm?: string;
}
/**
 * 
 * @export
 * @interface RealEstateAgentsListingResponseDto
 */
export interface RealEstateAgentsListingResponseDto {
    /**
     * 
     * @type {Array<RealEstateAgentListingAgentDto>}
     * @memberof RealEstateAgentsListingResponseDto
     */
    agents: Array<RealEstateAgentListingAgentDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateAgentsListingResponseDto
     */
    numberOfTotalAgents: number;
}
/**
 * 
 * @export
 * @interface RealEstateDayOpenHoursDtoDto
 */
export interface RealEstateDayOpenHoursDtoDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateDayOpenHoursDtoDto
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateDayOpenHoursDtoDto
     */
    to: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateDayOpenHoursDtoDto
     */
    nonstop: boolean;
}
/**
 * 
 * @export
 * @interface RealEstateListingAdvertisementsInfoDto
 */
export interface RealEstateListingAdvertisementsInfoDto {
    /**
     * 
     * @type {RealEstateAgencyListingAdvertisementsCountInfoDto}
     * @memberof RealEstateListingAdvertisementsInfoDto
     */
    advertisementsCountInfo: RealEstateAgencyListingAdvertisementsCountInfoDto;
    /**
     * 
     * @type {Array<RealEstateAgencyListingAdvertisementsPhotosDto>}
     * @memberof RealEstateListingAdvertisementsInfoDto
     */
    advertisementsPhotos?: Array<RealEstateAgencyListingAdvertisementsPhotosDto>;
}
/**
 * 
 * @export
 * @interface RealEstateLocationGpsDto
 */
export interface RealEstateLocationGpsDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateLocationGpsDto
     */
    lon: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateLocationGpsDto
     */
    lat: number;
}
/**
 * 
 * @export
 * @interface RealEstateNotificationEmailDetailPageDto
 */
export interface RealEstateNotificationEmailDetailPageDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateNotificationEmailDetailPageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateNotificationEmailDetailPageDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEstateNotificationEmailDetailPageDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealEstateNotificationEmailDetailPageDto
     */
    agreeToTerms: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealEstateNotificationEmailDetailPageDto
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface RealEstateOpenHoursDto
 */
export interface RealEstateOpenHoursDto {
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    monday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    tuesday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    wednesday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    thursday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    friday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    saturday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {RealEstateDayOpenHoursDtoDto}
     * @memberof RealEstateOpenHoursDto
     */
    sunday?: RealEstateDayOpenHoursDtoDto;
    /**
     * 
     * @type {string}
     * @memberof RealEstateOpenHoursDto
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface RealEstatePhoneNumbersDetailPageDto
 */
export interface RealEstatePhoneNumbersDetailPageDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstatePhoneNumbersDetailPageDto
     */
    main: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealEstatePhoneNumbersDetailPageDto
     */
    others?: Array<string>;
}
/**
 * 
 * @export
 * @interface RealEstateUserAdvertisementsStatsInfoDto
 */
export interface RealEstateUserAdvertisementsStatsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof RealEstateUserAdvertisementsStatsInfoDto
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserAdvertisementsStatsInfoDto
     */
    averagePrice: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserAdvertisementsStatsInfoDto
     */
    advertisementsCount: number;
}
/**
 * 
 * @export
 * @interface RealEstateUserPortfolioDto
 */
export interface RealEstateUserPortfolioDto {
    /**
     * 
     * @type {Array<RealEstateUserPortfolioItemsDto>}
     * @memberof RealEstateUserPortfolioDto
     */
    items: Array<RealEstateUserPortfolioItemsDto>;
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserPortfolioDto
     */
    totalCount: number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateUserPortfolioDto
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface RealEstateUserPortfolioItemsDto
 */
export interface RealEstateUserPortfolioItemsDto {
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserPortfolioItemsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RealEstateUserPortfolioItemsDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserPortfolioItemsDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateUserPortfolioItemsDto
     */
    percent: number;
}
/**
 * 
 * @export
 * @interface SearchFilterDataCategoryDto
 */
export interface SearchFilterDataCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterDataCategoryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataCategoryDto
     */
    name: SearchFilterDataCategoryDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataCategoryDto
     */
    sefName: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchFilterDataCategoryDto
     */
    isMain?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterDataCategoryDto
     */
    parentId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchFilterDataCategoryDtoNameEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS',
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface SearchFilterDataDto
 */
export interface SearchFilterDataDto {
    /**
     * 
     * @type {Array<SearchFilterDataLocationDto>}
     * @memberof SearchFilterDataDto
     */
    locations: Array<SearchFilterDataLocationDto>;
    /**
     * 
     * @type {Array<SearchFilterDataTransactionDto>}
     * @memberof SearchFilterDataDto
     */
    transactions: Array<SearchFilterDataTransactionDto>;
    /**
     * 
     * @type {Array<SearchFilterDataCategoryDto>}
     * @memberof SearchFilterDataDto
     */
    categories: Array<SearchFilterDataCategoryDto>;
}
/**
 * 
 * @export
 * @interface SearchFilterDataFromSefDtoRequest
 */
export interface SearchFilterDataFromSefDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataFromSefDtoRequest
     */
    locationSef?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataFromSefDtoRequest
     */
    categorySef?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataFromSefDtoRequest
     */
    transactionSef?: string;
}
/**
 * 
 * @export
 * @interface SearchFilterDataLocationDto
 */
export interface SearchFilterDataLocationDto {
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataLocationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataLocationDto
     */
    sefName: string;
    /**
     * 
     * @type {LocationResponseDto}
     * @memberof SearchFilterDataLocationDto
     */
    parent?: LocationResponseDto;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataLocationDto
     */
    type: SearchFilterDataLocationDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchFilterDataLocationDtoTypeEnum {
    Country = 'COUNTRY',
    County = 'COUNTY',
    District = 'DISTRICT',
    City = 'CITY',
    CityPart = 'CITY_PART',
    Street = 'STREET',
    StreetNumber = 'STREET_NUMBER'
}

/**
 * 
 * @export
 * @interface SearchFilterDataToSefDtoRequest
 */
export interface SearchFilterDataToSefDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataToSefDtoRequest
     */
    locationId?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterDataToSefDtoRequest
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilterDataToSefDtoRequest
     */
    transactionId?: number;
}
/**
 * 
 * @export
 * @interface SearchFilterDataTransactionDto
 */
export interface SearchFilterDataTransactionDto {
    /**
     * 
     * @type {number}
     * @memberof SearchFilterDataTransactionDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataTransactionDto
     */
    name: SearchFilterDataTransactionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterDataTransactionDto
     */
    sefName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchFilterDataTransactionDtoNameEnum {
    Purchase = 'PURCHASE',
    Hireout = 'HIREOUT',
    Rent = 'RENT',
    Exchange = 'EXCHANGE',
    Auction = 'AUCTION',
    Sale = 'SALE'
}

/**
 * 
 * @export
 * @interface SearchFilterHistogramFilterDto
 */
export interface SearchFilterHistogramFilterDto {
    /**
     * 
     * @type {string}
     * @memberof SearchFilterHistogramFilterDto
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFilterHistogramFilterDto
     */
    transaction?: SearchFilterHistogramFilterDtoTransactionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFilterHistogramFilterDto
     */
    categories?: Array<SearchFilterHistogramFilterDtoCategoriesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchFilterHistogramFilterDtoTransactionEnum {
    Purchase = 'PURCHASE',
    Hireout = 'HIREOUT',
    Rent = 'RENT',
    Exchange = 'EXCHANGE',
    Auction = 'AUCTION',
    Sale = 'SALE'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchFilterHistogramFilterDtoCategoriesEnum {
    RealEstates = 'REAL_ESTATES',
    Apartments = 'APARTMENTS',
    Houses = 'HOUSES',
    CottagesAndCabins = 'COTTAGES_AND_CABINS',
    Lands = 'LANDS',
    Spaces = 'SPACES',
    Objects = 'OBJECTS',
    SecondaryHouses = 'SECONDARY_HOUSES',
    SecondaryRecreationalProperties = 'SECONDARY_RECREATIONAL_PROPERTIES',
    SecondaryLands = 'SECONDARY_LANDS',
    SecondarySpacesAndObjects = 'SECONDARY_SPACES_AND_OBJECTS',
    StudioApartment = 'STUDIO_APARTMENT',
    DoubleStudioApartment = 'DOUBLE_STUDIO_APARTMENT',
    OneRoomApartment = 'ONE_ROOM_APARTMENT',
    TwoRoomApartment = 'TWO_ROOM_APARTMENT',
    ThreeRoomApartment = 'THREE_ROOM_APARTMENT',
    OtherTypeOfApartment = 'OTHER_TYPE_OF_APARTMENT',
    FourRoomApartment = 'FOUR_ROOM_APARTMENT',
    FivePlusRoomApartment = 'FIVE_PLUS_ROOM_APARTMENT',
    HolidayApartment = 'HOLIDAY_APARTMENT',
    Loft = 'LOFT',
    Maisonette = 'MAISONETTE',
    FamilyHouse = 'FAMILY_HOUSE',
    CountryHouse = 'COUNTRY_HOUSE',
    FarmSettlement = 'FARM_SETTLEMENT',
    MobileHouse = 'MOBILE_HOUSE',
    Houseboat = 'HOUSEBOAT',
    OtherObjectForHousing = 'OTHER_OBJECT_FOR_HOUSING',
    CabinAndLogCabin = 'CABIN_AND_LOG_CABIN',
    CottageAndRecreationHouse = 'COTTAGE_AND_RECREATION_HOUSE',
    GardenHut = 'GARDEN_HUT',
    OtherRecreationalObject = 'OTHER_RECREATIONAL_OBJECT',
    LandForFamilyHouse = 'LAND_FOR_FAMILY_HOUSE',
    RecreationalLand = 'RECREATIONAL_LAND',
    LandForHousingConstruction = 'LAND_FOR_HOUSING_CONSTRUCTION',
    LandForCivicAmenities = 'LAND_FOR_CIVIC_AMENITIES',
    CommercialZone = 'COMMERCIAL_ZONE',
    IndustrialZone = 'INDUSTRIAL_ZONE',
    MixedZone = 'MIXED_ZONE',
    Garden = 'GARDEN',
    Orchard = 'ORCHARD',
    VineyardAndHopGarden = 'VINEYARD_AND_HOP_GARDEN',
    MeadowAndPasture = 'MEADOW_AND_PASTURE',
    ArableLand = 'ARABLE_LAND',
    ForestLand = 'FOREST_LAND',
    PondAndLake = 'POND_AND_LAKE',
    LandForAdvertising = 'LAND_FOR_ADVERTISING',
    OtherTypeOfLand = 'OTHER_TYPE_OF_LAND',
    Offices = 'OFFICES',
    BusinessSpaces = 'BUSINESS_SPACES',
    RestaurantSpaces = 'RESTAURANT_SPACES',
    StorageAreas = 'STORAGE_AREAS',
    SpaceForProduction = 'SPACE_FOR_PRODUCTION',
    RepairArea = 'REPAIR_AREA',
    SportsFacilities = 'SPORTS_FACILITIES',
    SpaceForAdvertisement = 'SPACE_FOR_ADVERTISEMENT',
    OtherTypeOfSpace = 'OTHER_TYPE_OF_SPACE',
    ApartmentHouse = 'APARTMENT_HOUSE',
    RentalHouse = 'RENTAL_HOUSE',
    OfficeBuilding = 'OFFICE_BUILDING',
    CommercialObject = 'COMMERCIAL_OBJECT',
    Restaurant = 'RESTAURANT',
    PolyfunctionalBuilding = 'POLYFUNCTIONAL_BUILDING',
    Warehouse = 'WAREHOUSE',
    ManufacturingFacility = 'MANUFACTURING_FACILITY',
    RepairFacility = 'REPAIR_FACILITY',
    ObjectForBreedingAnimals = 'OBJECT_FOR_BREEDING_ANIMALS',
    HotelAndPension = 'HOTEL_AND_PENSION',
    Spa = 'SPA',
    HistoricalObject = 'HISTORICAL_OBJECT',
    CommercialFacility = 'COMMERCIAL_FACILITY',
    SportObject = 'SPORT_OBJECT',
    SmallPowerStation = 'SMALL_POWER_STATION',
    GasStation = 'GAS_STATION',
    MobileCellsAndStands = 'MOBILE_CELLS_AND_STANDS',
    OtherTypeOfObject = 'OTHER_TYPE_OF_OBJECT',
    DetachedGarage = 'DETACHED_GARAGE',
    ConsolidatedGround = 'CONSOLIDATED_GROUND',
    AgriculturalObject = 'AGRICULTURAL_OBJECT',
    OtherLandToBuilding = 'OTHER_LAND_TO_BUILDING',
    OtherAgriculturalLand = 'OTHER_AGRICULTURAL_LAND',
    SecondaryStudioApartment = 'SECONDARY_STUDIO_APARTMENT',
    SecondaryThreeRoomApartment = 'SECONDARY_THREE_ROOM_APARTMENT',
    SecondaryLandForFamilyHouses = 'SECONDARY_LAND_FOR_FAMILY_HOUSES',
    SecondaryRecreationalLand = 'SECONDARY_RECREATIONAL_LAND',
    SecondaryCommercialPlots = 'SECONDARY_COMMERCIAL_PLOTS',
    SecondaryGarden = 'SECONDARY_GARDEN',
    SecondaryOrchardsVineyardsHopFields = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS',
    SecondaryAgriculturalAndForestLand = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND',
    SecondaryGarageAndParking = 'SECONDARY_GARAGE_AND_PARKING',
    SecondaryOfficesAndAdministration = 'SECONDARY_OFFICES_AND_ADMINISTRATION',
    SecondaryTheShop = 'SECONDARY_THE_SHOP',
    SecondaryARestaurant = 'SECONDARY_A_RESTAURANT',
    SecondaryWarehouses = 'SECONDARY_WAREHOUSES',
    SecondaryProduction = 'SECONDARY_PRODUCTION',
    SecondaryAnotherSpaceAndObject = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT',
    SecondaryHotelGuesthouse = 'SECONDARY_HOTEL_GUESTHOUSE'
}

/**
 * 
 * @export
 * @interface SessionCountsDto
 */
export interface SessionCountsDto {
    /**
     * 
     * @type {number}
     * @memberof SessionCountsDto
     */
    observer: number;
    /**
     * 
     * @type {number}
     * @memberof SessionCountsDto
     */
    searchAgent: number;
}
/**
 * 
 * @export
 * @interface SessionDto
 */
export interface SessionDto {
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionDto
     */
    authType: SessionDtoAuthTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    nick?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    email?: string;
    /**
     * 
     * @type {SessionCountsDto}
     * @memberof SessionDto
     */
    counts: SessionCountsDto;
    /**
     * 
     * @type {SessionLinksDto}
     * @memberof SessionDto
     */
    links: SessionLinksDto;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDto
     */
    isAdmin: boolean;
    /**
     * 
     * @type {number}
     * @memberof SessionDto
     */
    lastDtiId: number;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    registrationCompletionUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    acceptGdprConditionsUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SessionDtoAuthTypeEnum {
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22
}

/**
 * 
 * @export
 * @interface SessionLinksDto
 */
export interface SessionLinksDto {
    /**
     * 
     * @type {string}
     * @memberof SessionLinksDto
     */
    gdprExport: string;
    /**
     * 
     * @type {string}
     * @memberof SessionLinksDto
     */
    gdprAnonymize: string;
}
/**
 * 
 * @export
 * @interface SuggestionDto
 */
export interface SuggestionDto {
    /**
     * 
     * @type {string}
     * @memberof SuggestionDto
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionDto
     */
    text: string;
}

/**
 * AdsApi - axios parameter creator
 * @export
 */
export const AdsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get head script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadScript: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ads/head-script`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdsApi - functional programming interface
 * @export
 */
export const AdsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get head script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeadScript(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeadScript(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdsApi - factory interface
 * @export
 */
export const AdsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get head script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeadScript(options?: any): AxiosPromise<string> {
            return localVarFp.getHeadScript(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdsApi - object-oriented interface
 * @export
 * @class AdsApi
 * @extends {BaseAPI}
 */
export class AdsApi extends BaseAPI {
    /**
     * 
     * @summary Get head script
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdsApi
     */
    public getHeadScript(options?: any) {
        return AdsApiFp(this.configuration).getHeadScript(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementAmenitiesApi - axios parameter creator
 * @export
 */
export const AdvertisementAmenitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Advertisement Amenities
         * @param {string} advertisementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmenities: async (advertisementId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisementId' is not null or undefined
            assertParamExists('getAmenities', 'advertisementId', advertisementId)
            const localVarPath = `/advertisement/amenities/{advertisementId}`
                .replace(`{${"advertisementId"}}`, encodeURIComponent(String(advertisementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementAmenitiesApi - functional programming interface
 * @export
 */
export const AdvertisementAmenitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementAmenitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement Amenities
         * @param {string} advertisementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmenities(advertisementId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementAmenityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmenities(advertisementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementAmenitiesApi - factory interface
 * @export
 */
export const AdvertisementAmenitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementAmenitiesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement Amenities
         * @param {string} advertisementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmenities(advertisementId: string, options?: any): AxiosPromise<AdvertisementAmenityResponseDto> {
            return localVarFp.getAmenities(advertisementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementAmenitiesApi - object-oriented interface
 * @export
 * @class AdvertisementAmenitiesApi
 * @extends {BaseAPI}
 */
export class AdvertisementAmenitiesApi extends BaseAPI {
    /**
     * 
     * @summary Get Advertisement Amenities
     * @param {string} advertisementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementAmenitiesApi
     */
    public getAmenities(advertisementId: string, options?: any) {
        return AdvertisementAmenitiesApiFp(this.configuration).getAmenities(advertisementId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementComparisonApi - axios parameter creator
 * @export
 */
export const AdvertisementComparisonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check Advertisements are in Comparison
         * @param {ComparisonAdvertisementsInComparisonDto} comparisonAdvertisementsInComparisonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areInComparison: async (comparisonAdvertisementsInComparisonDto: ComparisonAdvertisementsInComparisonDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'comparisonAdvertisementsInComparisonDto' is not null or undefined
            assertParamExists('areInComparison', 'comparisonAdvertisementsInComparisonDto', comparisonAdvertisementsInComparisonDto)
            const localVarPath = `/advertisement/comparison/are-in-comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comparisonAdvertisementsInComparisonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Advertisement To Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvertisementComparison: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createAdvertisementComparison', 'id', id)
            const localVarPath = `/advertisement/comparison/{id}/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Advertisement From Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvertisementComparison: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdvertisementComparison', 'id', id)
            const localVarPath = `/advertisement/comparison/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get advertisement is in comparison status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInComparison: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isInComparison', 'id', id)
            const localVarPath = `/advertisement/comparison/{id}/is-in-comparison`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementComparisonApi - functional programming interface
 * @export
 */
export const AdvertisementComparisonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementComparisonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check Advertisements are in Comparison
         * @param {ComparisonAdvertisementsInComparisonDto} comparisonAdvertisementsInComparisonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areInComparison(comparisonAdvertisementsInComparisonDto: ComparisonAdvertisementsInComparisonDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areInComparison(comparisonAdvertisementsInComparisonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Advertisement To Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdvertisementComparison(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdvertisementComparison(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Advertisement From Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvertisementComparison(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvertisementComparison(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get advertisement is in comparison status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isInComparison(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isInComparison(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementComparisonApi - factory interface
 * @export
 */
export const AdvertisementComparisonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementComparisonApiFp(configuration)
    return {
        /**
         * 
         * @summary Check Advertisements are in Comparison
         * @param {ComparisonAdvertisementsInComparisonDto} comparisonAdvertisementsInComparisonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areInComparison(comparisonAdvertisementsInComparisonDto: ComparisonAdvertisementsInComparisonDto, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.areInComparison(comparisonAdvertisementsInComparisonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Advertisement To Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvertisementComparison(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.createAdvertisementComparison(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Advertisement From Comparison
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvertisementComparison(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdvertisementComparison(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get advertisement is in comparison status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isInComparison(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isInComparison(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementComparisonApi - object-oriented interface
 * @export
 * @class AdvertisementComparisonApi
 * @extends {BaseAPI}
 */
export class AdvertisementComparisonApi extends BaseAPI {
    /**
     * 
     * @summary Check Advertisements are in Comparison
     * @param {ComparisonAdvertisementsInComparisonDto} comparisonAdvertisementsInComparisonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementComparisonApi
     */
    public areInComparison(comparisonAdvertisementsInComparisonDto: ComparisonAdvertisementsInComparisonDto, options?: any) {
        return AdvertisementComparisonApiFp(this.configuration).areInComparison(comparisonAdvertisementsInComparisonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Advertisement To Comparison
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementComparisonApi
     */
    public createAdvertisementComparison(id: string, options?: any) {
        return AdvertisementComparisonApiFp(this.configuration).createAdvertisementComparison(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Advertisement From Comparison
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementComparisonApi
     */
    public deleteAdvertisementComparison(id: string, options?: any) {
        return AdvertisementComparisonApiFp(this.configuration).deleteAdvertisementComparison(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get advertisement is in comparison status
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementComparisonApi
     */
    public isInComparison(id: string, options?: any) {
        return AdvertisementComparisonApiFp(this.configuration).isInComparison(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementDetailApi - axios parameter creator
 * @export
 */
export const AdvertisementDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Contact Advertiser
         * @param {string} id 
         * @param {AdvertisementContactDto} advertisementContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactAdvertiser: async (id: string, advertisementContactDto: AdvertisementContactDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contactAdvertiser', 'id', id)
            // verify required parameter 'advertisementContactDto' is not null or undefined
            assertParamExists('contactAdvertiser', 'advertisementContactDto', advertisementContactDto)
            const localVarPath = `/advertisement/detail/{id}/contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of exclusive devprojects
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exclusiveDevprojects: async (id: string, limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exclusiveDevprojects', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('exclusiveDevprojects', 'limit', limit)
            const localVarPath = `/advertisement/detail/{id}/exclusive-devprojects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertiser phone number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPhoneNumber: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdvertiserPhoneNumber', 'id', id)
            const localVarPath = `/advertisement/detail/{id}/advertiser-phone-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisement detail by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdAdvertisementDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdAdvertisementDetail', 'id', id)
            const localVarPath = `/advertisement/detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Other Advertiser Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherAdvertiserAdvertisements: async (id: string, limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOtherAdvertiserAdvertisements', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getOtherAdvertiserAdvertisements', 'limit', limit)
            const localVarPath = `/advertisement/detail/{id}/other-from-advertiser`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Silimar Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAdvertisements: async (id: string, limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSimilarAdvertisements', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getSimilarAdvertisements', 'limit', limit)
            const localVarPath = `/advertisement/detail/{id}/similar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report Advertiement
         * @param {string} id 
         * @param {AdvertisementReportDto} advertisementReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAdvertisement: async (id: string, advertisementReportDto: AdvertisementReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reportAdvertisement', 'id', id)
            // verify required parameter 'advertisementReportDto' is not null or undefined
            assertParamExists('reportAdvertisement', 'advertisementReportDto', advertisementReportDto)
            const localVarPath = `/advertisement/detail/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementDetailApi - functional programming interface
 * @export
 */
export const AdvertisementDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Contact Advertiser
         * @param {string} id 
         * @param {AdvertisementContactDto} advertisementContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactAdvertiser(id: string, advertisementContactDto: AdvertisementContactDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactAdvertiser(id, advertisementContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of exclusive devprojects
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exclusiveDevprojects(id: string, limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementDetaiDevProjectsExclusiveProjectsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exclusiveDevprojects(id, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertiser phone number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertiserPhoneNumber(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertiserPhoneNumber(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisement detail by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdAdvertisementDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdAdvertisementDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Other Advertiser Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtherAdvertiserAdvertisements(id: string, limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementDetailOtherAdvertiserAdvertisementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtherAdvertiserAdvertisements(id, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Silimar Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarAdvertisements(id: string, limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementDetailSimilarAdvertisementsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimilarAdvertisements(id, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report Advertiement
         * @param {string} id 
         * @param {AdvertisementReportDto} advertisementReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportAdvertisement(id: string, advertisementReportDto: AdvertisementReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportAdvertisement(id, advertisementReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementDetailApi - factory interface
 * @export
 */
export const AdvertisementDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementDetailApiFp(configuration)
    return {
        /**
         * 
         * @summary Contact Advertiser
         * @param {string} id 
         * @param {AdvertisementContactDto} advertisementContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactAdvertiser(id: string, advertisementContactDto: AdvertisementContactDto, options?: any): AxiosPromise<void> {
            return localVarFp.contactAdvertiser(id, advertisementContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of exclusive devprojects
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exclusiveDevprojects(id: string, limit: number, options?: any): AxiosPromise<AdvertisementDetaiDevProjectsExclusiveProjectsDto> {
            return localVarFp.exclusiveDevprojects(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertiser phone number
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertiserPhoneNumber(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getAdvertiserPhoneNumber(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisement detail by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdAdvertisementDetail(id: string, options?: any): AxiosPromise<AdvertisementDetailDto> {
            return localVarFp.getByIdAdvertisementDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Other Advertiser Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherAdvertiserAdvertisements(id: string, limit: number, options?: any): AxiosPromise<AdvertisementDetailOtherAdvertiserAdvertisementDto> {
            return localVarFp.getOtherAdvertiserAdvertisements(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Silimar Advertisements
         * @param {string} id 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAdvertisements(id: string, limit: number, options?: any): AxiosPromise<AdvertisementDetailSimilarAdvertisementsDto> {
            return localVarFp.getSimilarAdvertisements(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report Advertiement
         * @param {string} id 
         * @param {AdvertisementReportDto} advertisementReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportAdvertisement(id: string, advertisementReportDto: AdvertisementReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.reportAdvertisement(id, advertisementReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementDetailApi - object-oriented interface
 * @export
 * @class AdvertisementDetailApi
 * @extends {BaseAPI}
 */
export class AdvertisementDetailApi extends BaseAPI {
    /**
     * 
     * @summary Contact Advertiser
     * @param {string} id 
     * @param {AdvertisementContactDto} advertisementContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public contactAdvertiser(id: string, advertisementContactDto: AdvertisementContactDto, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).contactAdvertiser(id, advertisementContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of exclusive devprojects
     * @param {string} id 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public exclusiveDevprojects(id: string, limit: number, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).exclusiveDevprojects(id, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertiser phone number
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public getAdvertiserPhoneNumber(id: string, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).getAdvertiserPhoneNumber(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisement detail by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public getByIdAdvertisementDetail(id: string, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).getByIdAdvertisementDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Other Advertiser Advertisements
     * @param {string} id 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public getOtherAdvertiserAdvertisements(id: string, limit: number, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).getOtherAdvertiserAdvertisements(id, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Silimar Advertisements
     * @param {string} id 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public getSimilarAdvertisements(id: string, limit: number, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).getSimilarAdvertisements(id, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report Advertiement
     * @param {string} id 
     * @param {AdvertisementReportDto} advertisementReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementDetailApi
     */
    public reportAdvertisement(id: string, advertisementReportDto: AdvertisementReportDto, options?: any) {
        return AdvertisementDetailApiFp(this.configuration).reportAdvertisement(id, advertisementReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementFavouriteApi - axios parameter creator
 * @export
 */
export const AdvertisementFavouriteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check Advertisements are in Favourite
         * @param {FavouriteAdvertisementsInFavouriteDto} favouriteAdvertisementsInFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areInFavourite: async (favouriteAdvertisementsInFavouriteDto: FavouriteAdvertisementsInFavouriteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'favouriteAdvertisementsInFavouriteDto' is not null or undefined
            assertParamExists('areInFavourite', 'favouriteAdvertisementsInFavouriteDto', favouriteAdvertisementsInFavouriteDto)
            const localVarPath = `/advertisement/favourite/are-favourite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favouriteAdvertisementsInFavouriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Advertisement To Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvertisementFavourite: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createAdvertisementFavourite', 'id', id)
            const localVarPath = `/advertisement/favourite/{id}/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Advertisement From Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvertisementFavourite: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdvertisementFavourite', 'id', id)
            const localVarPath = `/advertisement/favourite/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisement Is Favourite Status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFavourite: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isFavourite', 'id', id)
            const localVarPath = `/advertisement/favourite/{id}/is-favourite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementFavouriteApi - functional programming interface
 * @export
 */
export const AdvertisementFavouriteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementFavouriteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check Advertisements are in Favourite
         * @param {FavouriteAdvertisementsInFavouriteDto} favouriteAdvertisementsInFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areInFavourite(favouriteAdvertisementsInFavouriteDto: FavouriteAdvertisementsInFavouriteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areInFavourite(favouriteAdvertisementsInFavouriteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Advertisement To Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdvertisementFavourite(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdvertisementFavourite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Advertisement From Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdvertisementFavourite(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdvertisementFavourite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisement Is Favourite Status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isFavourite(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isFavourite(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementFavouriteApi - factory interface
 * @export
 */
export const AdvertisementFavouriteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementFavouriteApiFp(configuration)
    return {
        /**
         * 
         * @summary Check Advertisements are in Favourite
         * @param {FavouriteAdvertisementsInFavouriteDto} favouriteAdvertisementsInFavouriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areInFavourite(favouriteAdvertisementsInFavouriteDto: FavouriteAdvertisementsInFavouriteDto, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.areInFavourite(favouriteAdvertisementsInFavouriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Advertisement To Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdvertisementFavourite(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.createAdvertisementFavourite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Advertisement From Favourite
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdvertisementFavourite(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdvertisementFavourite(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisement Is Favourite Status
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFavourite(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isFavourite(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementFavouriteApi - object-oriented interface
 * @export
 * @class AdvertisementFavouriteApi
 * @extends {BaseAPI}
 */
export class AdvertisementFavouriteApi extends BaseAPI {
    /**
     * 
     * @summary Check Advertisements are in Favourite
     * @param {FavouriteAdvertisementsInFavouriteDto} favouriteAdvertisementsInFavouriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementFavouriteApi
     */
    public areInFavourite(favouriteAdvertisementsInFavouriteDto: FavouriteAdvertisementsInFavouriteDto, options?: any) {
        return AdvertisementFavouriteApiFp(this.configuration).areInFavourite(favouriteAdvertisementsInFavouriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Advertisement To Favourite
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementFavouriteApi
     */
    public createAdvertisementFavourite(id: string, options?: any) {
        return AdvertisementFavouriteApiFp(this.configuration).createAdvertisementFavourite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Advertisement From Favourite
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementFavouriteApi
     */
    public deleteAdvertisementFavourite(id: string, options?: any) {
        return AdvertisementFavouriteApiFp(this.configuration).deleteAdvertisementFavourite(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisement Is Favourite Status
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementFavouriteApi
     */
    public isFavourite(id: string, options?: any) {
        return AdvertisementFavouriteApiFp(this.configuration).isFavourite(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementListingApi - axios parameter creator
 * @export
 */
export const AdvertisementListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Advertisements By Filter
         * @param {AdvertisementListingFilterDto} advertisementListingFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisements: async (advertisementListingFilterDto: AdvertisementListingFilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisementListingFilterDto' is not null or undefined
            assertParamExists('getAdvertisements', 'advertisementListingFilterDto', advertisementListingFilterDto)
            const localVarPath = `/advertisement/listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementListingFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisements Count By Filter
         * @param {AdvertisementListingFilterDataDto} advertisementListingFilterDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsCount: async (advertisementListingFilterDataDto: AdvertisementListingFilterDataDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisementListingFilterDataDto' is not null or undefined
            assertParamExists('getAdvertisementsCount', 'advertisementListingFilterDataDto', advertisementListingFilterDataDto)
            const localVarPath = `/advertisement/listing/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementListingFilterDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advetisement Listing Dev Projects
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevProjects: async (limit: number, skip: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getDevProjects', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getDevProjects', 'skip', skip)
            const localVarPath = `/advertisement/listing/dev-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementListingApi - functional programming interface
 * @export
 */
export const AdvertisementListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Advertisements By Filter
         * @param {AdvertisementListingFilterDto} advertisementListingFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisements(advertisementListingFilterDto: AdvertisementListingFilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementsListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisements(advertisementListingFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisements Count By Filter
         * @param {AdvertisementListingFilterDataDto} advertisementListingFilterDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementsCount(advertisementListingFilterDataDto: AdvertisementListingFilterDataDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementsCount(advertisementListingFilterDataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advetisement Listing Dev Projects
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevProjects(limit: number, skip: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementListingDevProjectsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevProjects(limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementListingApi - factory interface
 * @export
 */
export const AdvertisementListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Advertisements By Filter
         * @param {AdvertisementListingFilterDto} advertisementListingFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisements(advertisementListingFilterDto: AdvertisementListingFilterDto, options?: any): AxiosPromise<AdvertisementsListDto> {
            return localVarFp.getAdvertisements(advertisementListingFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisements Count By Filter
         * @param {AdvertisementListingFilterDataDto} advertisementListingFilterDataDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsCount(advertisementListingFilterDataDto: AdvertisementListingFilterDataDto, options?: any): AxiosPromise<number> {
            return localVarFp.getAdvertisementsCount(advertisementListingFilterDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advetisement Listing Dev Projects
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevProjects(limit: number, skip: number, options?: any): AxiosPromise<AdvertisementListingDevProjectsDto> {
            return localVarFp.getDevProjects(limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementListingApi - object-oriented interface
 * @export
 * @class AdvertisementListingApi
 * @extends {BaseAPI}
 */
export class AdvertisementListingApi extends BaseAPI {
    /**
     * 
     * @summary Get Advertisements By Filter
     * @param {AdvertisementListingFilterDto} advertisementListingFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementListingApi
     */
    public getAdvertisements(advertisementListingFilterDto: AdvertisementListingFilterDto, options?: any) {
        return AdvertisementListingApiFp(this.configuration).getAdvertisements(advertisementListingFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisements Count By Filter
     * @param {AdvertisementListingFilterDataDto} advertisementListingFilterDataDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementListingApi
     */
    public getAdvertisementsCount(advertisementListingFilterDataDto: AdvertisementListingFilterDataDto, options?: any) {
        return AdvertisementListingApiFp(this.configuration).getAdvertisementsCount(advertisementListingFilterDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advetisement Listing Dev Projects
     * @param {number} limit 
     * @param {number} skip 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementListingApi
     */
    public getDevProjects(limit: number, skip: number, options?: any) {
        return AdvertisementListingApiFp(this.configuration).getDevProjects(limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementProjectsSearchMapApi - axios parameter creator
 * @export
 */
export const AdvertisementProjectsSearchMapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get projects for search map by filter
         * @param {AdvertisementProjectsSearchMapFilterDto} advertisementProjectsSearchMapFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (advertisementProjectsSearchMapFilterDto: AdvertisementProjectsSearchMapFilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisementProjectsSearchMapFilterDto' is not null or undefined
            assertParamExists('getProjects', 'advertisementProjectsSearchMapFilterDto', advertisementProjectsSearchMapFilterDto)
            const localVarPath = `/advertisement/projects/search-map/get-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementProjectsSearchMapFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementProjectsSearchMapApi - functional programming interface
 * @export
 */
export const AdvertisementProjectsSearchMapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementProjectsSearchMapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get projects for search map by filter
         * @param {AdvertisementProjectsSearchMapFilterDto} advertisementProjectsSearchMapFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(advertisementProjectsSearchMapFilterDto: AdvertisementProjectsSearchMapFilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdvertisementProjectsSearchMapProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(advertisementProjectsSearchMapFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementProjectsSearchMapApi - factory interface
 * @export
 */
export const AdvertisementProjectsSearchMapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementProjectsSearchMapApiFp(configuration)
    return {
        /**
         * 
         * @summary Get projects for search map by filter
         * @param {AdvertisementProjectsSearchMapFilterDto} advertisementProjectsSearchMapFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(advertisementProjectsSearchMapFilterDto: AdvertisementProjectsSearchMapFilterDto, options?: any): AxiosPromise<Array<AdvertisementProjectsSearchMapProjectDto>> {
            return localVarFp.getProjects(advertisementProjectsSearchMapFilterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementProjectsSearchMapApi - object-oriented interface
 * @export
 * @class AdvertisementProjectsSearchMapApi
 * @extends {BaseAPI}
 */
export class AdvertisementProjectsSearchMapApi extends BaseAPI {
    /**
     * 
     * @summary Get projects for search map by filter
     * @param {AdvertisementProjectsSearchMapFilterDto} advertisementProjectsSearchMapFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementProjectsSearchMapApi
     */
    public getProjects(advertisementProjectsSearchMapFilterDto: AdvertisementProjectsSearchMapFilterDto, options?: any) {
        return AdvertisementProjectsSearchMapApiFp(this.configuration).getProjects(advertisementProjectsSearchMapFilterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvertisementSearchAgentApi - axios parameter creator
 * @export
 */
export const AdvertisementSearchAgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create search agent for advertisements filter
         * @param {AdvertisementSearchAgentSaveSearchAgentDto} advertisementSearchAgentSaveSearchAgentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSearchAgent: async (advertisementSearchAgentSaveSearchAgentDto: AdvertisementSearchAgentSaveSearchAgentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertisementSearchAgentSaveSearchAgentDto' is not null or undefined
            assertParamExists('saveSearchAgent', 'advertisementSearchAgentSaveSearchAgentDto', advertisementSearchAgentSaveSearchAgentDto)
            const localVarPath = `/advertisement/search-agent/search-agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advertisementSearchAgentSaveSearchAgentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementSearchAgentApi - functional programming interface
 * @export
 */
export const AdvertisementSearchAgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementSearchAgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create search agent for advertisements filter
         * @param {AdvertisementSearchAgentSaveSearchAgentDto} advertisementSearchAgentSaveSearchAgentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto: AdvertisementSearchAgentSaveSearchAgentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementSearchAgentApi - factory interface
 * @export
 */
export const AdvertisementSearchAgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementSearchAgentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create search agent for advertisements filter
         * @param {AdvertisementSearchAgentSaveSearchAgentDto} advertisementSearchAgentSaveSearchAgentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto: AdvertisementSearchAgentSaveSearchAgentDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvertisementSearchAgentApi - object-oriented interface
 * @export
 * @class AdvertisementSearchAgentApi
 * @extends {BaseAPI}
 */
export class AdvertisementSearchAgentApi extends BaseAPI {
    /**
     * 
     * @summary Create search agent for advertisements filter
     * @param {AdvertisementSearchAgentSaveSearchAgentDto} advertisementSearchAgentSaveSearchAgentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementSearchAgentApi
     */
    public saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto: AdvertisementSearchAgentSaveSearchAgentDto, options?: any) {
        return AdvertisementSearchAgentApiFp(this.configuration).saveSearchAgent(advertisementSearchAgentSaveSearchAgentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All preflight requests
         * @param {string} anyroute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerPreflight: async (anyroute: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'anyroute' is not null or undefined
            assertParamExists('appControllerPreflight', 'anyroute', anyroute)
            const localVarPath = `/{anyroute+}`
                .replace(`{${"anyroute+"}}`, encodeURIComponent(String(anyroute)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary All preflight requests
         * @param {string} anyroute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerPreflight(anyroute: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerPreflight(anyroute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary All preflight requests
         * @param {string} anyroute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerPreflight(anyroute: string, options?: any): AxiosPromise<void> {
            return localVarFp.appControllerPreflight(anyroute, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary All preflight requests
     * @param {string} anyroute 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerPreflight(anyroute: string, options?: any) {
        return DefaultApiFp(this.configuration).appControllerPreflight(anyroute, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevProjectExclusiveProjectListingApi - axios parameter creator
 * @export
 */
export const DevProjectExclusiveProjectListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Exclusive Projects
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExclusiveProjects: async (limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getExclusiveProjects', 'limit', limit)
            const localVarPath = `/dev-project/exclusive-project-listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevProjectExclusiveProjectListingApi - functional programming interface
 * @export
 */
export const DevProjectExclusiveProjectListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevProjectExclusiveProjectListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Exclusive Projects
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExclusiveProjects(limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevProjectsExclusiveProjectDtoo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExclusiveProjects(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevProjectExclusiveProjectListingApi - factory interface
 * @export
 */
export const DevProjectExclusiveProjectListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevProjectExclusiveProjectListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Exclusive Projects
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExclusiveProjects(limit: number, options?: any): AxiosPromise<Array<DevProjectsExclusiveProjectDtoo>> {
            return localVarFp.getExclusiveProjects(limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevProjectExclusiveProjectListingApi - object-oriented interface
 * @export
 * @class DevProjectExclusiveProjectListingApi
 * @extends {BaseAPI}
 */
export class DevProjectExclusiveProjectListingApi extends BaseAPI {
    /**
     * 
     * @summary Get Exclusive Projects
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevProjectExclusiveProjectListingApi
     */
    public getExclusiveProjects(limit: number, options?: any) {
        return DevProjectExclusiveProjectListingApiFp(this.configuration).getExclusiveProjects(limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HomePageDevProjectsApi - axios parameter creator
 * @export
 */
export const HomePageDevProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Dev Projects In Home Page
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageDevProjects: async (limit: number, skip: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getHomePageDevProjects', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getHomePageDevProjects', 'skip', skip)
            const localVarPath = `/home-page/dev-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomePageDevProjectsApi - functional programming interface
 * @export
 */
export const HomePageDevProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomePageDevProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Dev Projects In Home Page
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomePageDevProjects(limit: number, skip: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomePageDevProjectsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomePageDevProjects(limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomePageDevProjectsApi - factory interface
 * @export
 */
export const HomePageDevProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomePageDevProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Dev Projects In Home Page
         * @param {number} limit 
         * @param {number} skip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomePageDevProjects(limit: number, skip: number, options?: any): AxiosPromise<HomePageDevProjectsDto> {
            return localVarFp.getHomePageDevProjects(limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomePageDevProjectsApi - object-oriented interface
 * @export
 * @class HomePageDevProjectsApi
 * @extends {BaseAPI}
 */
export class HomePageDevProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Get Dev Projects In Home Page
     * @param {number} limit 
     * @param {number} skip 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomePageDevProjectsApi
     */
    public getHomePageDevProjects(limit: number, skip: number, options?: any) {
        return HomePageDevProjectsApiFp(this.configuration).getHomePageDevProjects(limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Locations Suggestions
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationSuggestions: async (query: string, limit: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getLocationSuggestions', 'query', query)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getLocationSuggestions', 'limit', limit)
            const localVarPath = `/location/find/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Locations By Ids
         * @param {LocationsByIdsRequestDto} locationsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsByIds: async (locationsByIdsRequestDto: LocationsByIdsRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationsByIdsRequestDto' is not null or undefined
            assertParamExists('getLocationsByIds', 'locationsByIdsRequestDto', locationsByIdsRequestDto)
            const localVarPath = `/location/find/by-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationsByIdsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Locations By Sef Names
         * @param {LocationsBySefNamesRequestDto} locationsBySefNamesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsBySefNames: async (locationsBySefNamesRequestDto: LocationsBySefNamesRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationsBySefNamesRequestDto' is not null or undefined
            assertParamExists('getLocationsBySefNames', 'locationsBySefNamesRequestDto', locationsBySefNamesRequestDto)
            const localVarPath = `/location/find/by-sef-names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationsBySefNamesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Locations Suggestions
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationSuggestions(query: string, limit: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationSuggestions(query, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Locations By Ids
         * @param {LocationsByIdsRequestDto} locationsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsByIds(locationsByIdsRequestDto: LocationsByIdsRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsByIds(locationsByIdsRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Locations By Sef Names
         * @param {LocationsBySefNamesRequestDto} locationsBySefNamesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsBySefNames(locationsBySefNamesRequestDto: LocationsBySefNamesRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsBySefNames(locationsBySefNamesRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Locations Suggestions
         * @param {string} query 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationSuggestions(query: string, limit: number, options?: any): AxiosPromise<Array<LocationResponseDto>> {
            return localVarFp.getLocationSuggestions(query, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Locations By Ids
         * @param {LocationsByIdsRequestDto} locationsByIdsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsByIds(locationsByIdsRequestDto: LocationsByIdsRequestDto, options?: any): AxiosPromise<Array<LocationResponseDto>> {
            return localVarFp.getLocationsByIds(locationsByIdsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Locations By Sef Names
         * @param {LocationsBySefNamesRequestDto} locationsBySefNamesRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsBySefNames(locationsBySefNamesRequestDto: LocationsBySefNamesRequestDto, options?: any): AxiosPromise<Array<LocationResponseDto>> {
            return localVarFp.getLocationsBySefNames(locationsBySefNamesRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary Get Locations Suggestions
     * @param {string} query 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationSuggestions(query: string, limit: number, options?: any) {
        return LocationApiFp(this.configuration).getLocationSuggestions(query, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Locations By Ids
     * @param {LocationsByIdsRequestDto} locationsByIdsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationsByIds(locationsByIdsRequestDto: LocationsByIdsRequestDto, options?: any) {
        return LocationApiFp(this.configuration).getLocationsByIds(locationsByIdsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Locations By Sef Names
     * @param {LocationsBySefNamesRequestDto} locationsBySefNamesRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationsBySefNames(locationsBySefNamesRequestDto: LocationsBySefNamesRequestDto, options?: any) {
        return LocationApiFp(this.configuration).getLocationsBySefNames(locationsBySefNamesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationCenterApi - axios parameter creator
 * @export
 */
export const NotificationCenterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notification
         * @param {'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION'} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification: async (section?: 'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notification/center/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationCenterApi - functional programming interface
 * @export
 */
export const NotificationCenterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationCenterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notification
         * @param {'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION'} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotification(section?: 'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationCenterApi - factory interface
 * @export
 */
export const NotificationCenterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationCenterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notification
         * @param {'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION'} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(section?: 'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION', options?: any): AxiosPromise<NotificationResponseDto> {
            return localVarFp.getNotification(section, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationCenterApi - object-oriented interface
 * @export
 * @class NotificationCenterApi
 * @extends {BaseAPI}
 */
export class NotificationCenterApi extends BaseAPI {
    /**
     * 
     * @summary Get notification
     * @param {'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION'} [section] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationCenterApi
     */
    public getNotification(section?: 'ALL' | 'HOMEPAGE' | 'PAYMENTS' | 'SEARCH_RESULTS' | 'PHOTOS' | 'AD_INSERTION', options?: any) {
        return NotificationCenterApiFp(this.configuration).getNotification(section, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationEmailApi - axios parameter creator
 * @export
 */
export const NotificationEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send new Suggestion
         * @param {SuggestionDto} suggestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerSendSuggestion: async (suggestionDto: SuggestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'suggestionDto' is not null or undefined
            assertParamExists('notificationControllerSendSuggestion', 'suggestionDto', suggestionDto)
            const localVarPath = `/notification/email/send-suggestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suggestionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to the contact center from about us page
         * @param {NotificationEmailAboutUsPageDto} notificationEmailAboutUsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromAboutUsPage: async (notificationEmailAboutUsPageDto: NotificationEmailAboutUsPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationEmailAboutUsPageDto' is not null or undefined
            assertParamExists('sendEmailFromAboutUsPage', 'notificationEmailAboutUsPageDto', notificationEmailAboutUsPageDto)
            const localVarPath = `/notification/email/about-us-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationEmailAboutUsPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to the sales department from information page
         * @param {NotificationEmailInformationPageDto} notificationEmailInformationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromInformationPage: async (notificationEmailInformationPageDto: NotificationEmailInformationPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationEmailInformationPageDto' is not null or undefined
            assertParamExists('sendEmailFromInformationPage', 'notificationEmailInformationPageDto', notificationEmailInformationPageDto)
            const localVarPath = `/notification/email/information-page-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationEmailInformationPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to the sales department from leadgeneration page
         * @param {NotificationEmailLeadgenerationPageDto} notificationEmailLeadgenerationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromLeadgenerationPage: async (notificationEmailLeadgenerationPageDto: NotificationEmailLeadgenerationPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationEmailLeadgenerationPageDto' is not null or undefined
            assertParamExists('sendEmailFromLeadgenerationPage', 'notificationEmailLeadgenerationPageDto', notificationEmailLeadgenerationPageDto)
            const localVarPath = `/notification/email/leadgeneration-page-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationEmailLeadgenerationPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to support for new firm registration in Neh Admin
         * @param {NotificationEmailFirmRegistrationPageDto} notificationEmailFirmRegistrationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFirmRegistrationEmail: async (notificationEmailFirmRegistrationPageDto: NotificationEmailFirmRegistrationPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationEmailFirmRegistrationPageDto' is not null or undefined
            assertParamExists('sendFirmRegistrationEmail', 'notificationEmailFirmRegistrationPageDto', notificationEmailFirmRegistrationPageDto)
            const localVarPath = `/notification/email/firm-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationEmailFirmRegistrationPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationEmailApi - functional programming interface
 * @export
 */
export const NotificationEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send new Suggestion
         * @param {SuggestionDto} suggestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerSendSuggestion(suggestionDto: SuggestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerSendSuggestion(suggestionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to the contact center from about us page
         * @param {NotificationEmailAboutUsPageDto} notificationEmailAboutUsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto: NotificationEmailAboutUsPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to the sales department from information page
         * @param {NotificationEmailInformationPageDto} notificationEmailInformationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailFromInformationPage(notificationEmailInformationPageDto: NotificationEmailInformationPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailFromInformationPage(notificationEmailInformationPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to the sales department from leadgeneration page
         * @param {NotificationEmailLeadgenerationPageDto} notificationEmailLeadgenerationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto: NotificationEmailLeadgenerationPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to support for new firm registration in Neh Admin
         * @param {NotificationEmailFirmRegistrationPageDto} notificationEmailFirmRegistrationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto: NotificationEmailFirmRegistrationPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationEmailApi - factory interface
 * @export
 */
export const NotificationEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationEmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Send new Suggestion
         * @param {SuggestionDto} suggestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerSendSuggestion(suggestionDto: SuggestionDto, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerSendSuggestion(suggestionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to the contact center from about us page
         * @param {NotificationEmailAboutUsPageDto} notificationEmailAboutUsPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto: NotificationEmailAboutUsPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to the sales department from information page
         * @param {NotificationEmailInformationPageDto} notificationEmailInformationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromInformationPage(notificationEmailInformationPageDto: NotificationEmailInformationPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailFromInformationPage(notificationEmailInformationPageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to the sales department from leadgeneration page
         * @param {NotificationEmailLeadgenerationPageDto} notificationEmailLeadgenerationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto: NotificationEmailLeadgenerationPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to support for new firm registration in Neh Admin
         * @param {NotificationEmailFirmRegistrationPageDto} notificationEmailFirmRegistrationPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto: NotificationEmailFirmRegistrationPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationEmailApi - object-oriented interface
 * @export
 * @class NotificationEmailApi
 * @extends {BaseAPI}
 */
export class NotificationEmailApi extends BaseAPI {
    /**
     * 
     * @summary Send new Suggestion
     * @param {SuggestionDto} suggestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationEmailApi
     */
    public notificationControllerSendSuggestion(suggestionDto: SuggestionDto, options?: any) {
        return NotificationEmailApiFp(this.configuration).notificationControllerSendSuggestion(suggestionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to the contact center from about us page
     * @param {NotificationEmailAboutUsPageDto} notificationEmailAboutUsPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationEmailApi
     */
    public sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto: NotificationEmailAboutUsPageDto, options?: any) {
        return NotificationEmailApiFp(this.configuration).sendEmailFromAboutUsPage(notificationEmailAboutUsPageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to the sales department from information page
     * @param {NotificationEmailInformationPageDto} notificationEmailInformationPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationEmailApi
     */
    public sendEmailFromInformationPage(notificationEmailInformationPageDto: NotificationEmailInformationPageDto, options?: any) {
        return NotificationEmailApiFp(this.configuration).sendEmailFromInformationPage(notificationEmailInformationPageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to the sales department from leadgeneration page
     * @param {NotificationEmailLeadgenerationPageDto} notificationEmailLeadgenerationPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationEmailApi
     */
    public sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto: NotificationEmailLeadgenerationPageDto, options?: any) {
        return NotificationEmailApiFp(this.configuration).sendEmailFromLeadgenerationPage(notificationEmailLeadgenerationPageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to support for new firm registration in Neh Admin
     * @param {NotificationEmailFirmRegistrationPageDto} notificationEmailFirmRegistrationPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationEmailApi
     */
    public sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto: NotificationEmailFirmRegistrationPageDto, options?: any) {
        return NotificationEmailApiFp(this.configuration).sendFirmRegistrationEmail(notificationEmailFirmRegistrationPageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAdvertisementDetailListingApi - axios parameter creator
 * @export
 */
export const RealEstateAdvertisementDetailListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of advertisements for agency detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsListForAgencyDetail: async (userId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdvertisementsListForAgencyDetail', 'userId', userId)
            const localVarPath = `/real-estate/advertisement-detail-listing/agency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of advertisements for agent detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsListForAgentDetail: async (userId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAdvertisementsListForAgentDetail', 'userId', userId)
            const localVarPath = `/real-estate/advertisement-detail-listing/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAdvertisementDetailListingApi - functional programming interface
 * @export
 */
export const RealEstateAdvertisementDetailListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAdvertisementDetailListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of advertisements for agency detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementsListForAgencyDetail(userId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAdvertisementListingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementsListForAgencyDetail(userId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of advertisements for agent detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementsListForAgentDetail(userId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAdvertisementListingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementsListForAgentDetail(userId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAdvertisementDetailListingApi - factory interface
 * @export
 */
export const RealEstateAdvertisementDetailListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAdvertisementDetailListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of advertisements for agency detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsListForAgencyDetail(userId: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAdvertisementListingResponseDto> {
            return localVarFp.getAdvertisementsListForAgencyDetail(userId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of advertisements for agent detail
         * @param {string} userId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementsListForAgentDetail(userId: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAdvertisementListingResponseDto> {
            return localVarFp.getAdvertisementsListForAgentDetail(userId, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAdvertisementDetailListingApi - object-oriented interface
 * @export
 * @class RealEstateAdvertisementDetailListingApi
 * @extends {BaseAPI}
 */
export class RealEstateAdvertisementDetailListingApi extends BaseAPI {
    /**
     * 
     * @summary Get list of advertisements for agency detail
     * @param {string} userId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAdvertisementDetailListingApi
     */
    public getAdvertisementsListForAgencyDetail(userId: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAdvertisementDetailListingApiFp(this.configuration).getAdvertisementsListForAgencyDetail(userId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of advertisements for agent detail
     * @param {string} userId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAdvertisementDetailListingApi
     */
    public getAdvertisementsListForAgentDetail(userId: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAdvertisementDetailListingApiFp(this.configuration).getAdvertisementsListForAgentDetail(userId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgencyDetailApi - axios parameter creator
 * @export
 */
export const RealEstateAgencyDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Agency phone by agency id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyPhoneAgencyById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgencyPhoneAgencyById', 'id', id)
            const localVarPath = `/real-estate/agency/detail/{id}/phone`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agency data by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgencyDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getByIdRealEstateAgencyDetail', 'id', id)
            const localVarPath = `/real-estate/agency/detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agency redirect data by slug
         * @param {boolean} isBranch 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySlugRealEstateAgencyRedirect: async (isBranch: boolean, slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isBranch' is not null or undefined
            assertParamExists('getBySlugRealEstateAgencyRedirect', 'isBranch', isBranch)
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getBySlugRealEstateAgencyRedirect', 'slug', slug)
            const localVarPath = `/real-estate/agency/detail/slug/{slug}/{isBranch}`
                .replace(`{${"isBranch"}}`, encodeURIComponent(String(isBranch)))
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agents data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealEstateAgencyAgentsByParentId: async (parentId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getRealEstateAgencyAgentsByParentId', 'parentId', parentId)
            const localVarPath = `/real-estate/agency/detail/agents/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Branches data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealEstateAgencyBranchesByParentId: async (parentId: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('getRealEstateAgencyBranchesByParentId', 'parentId', parentId)
            const localVarPath = `/real-estate/agency/detail/branches/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to agency from agency detail page
         * @param {string} id 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromAgencyDetailPage: async (id: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendEmailFromAgencyDetailPage', 'id', id)
            // verify required parameter 'realEstateNotificationEmailDetailPageDto' is not null or undefined
            assertParamExists('sendEmailFromAgencyDetailPage', 'realEstateNotificationEmailDetailPageDto', realEstateNotificationEmailDetailPageDto)
            const localVarPath = `/real-estate/agency/detail/{id}/agency-detail-page-mail-contact`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realEstateNotificationEmailDetailPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgencyDetailApi - functional programming interface
 * @export
 */
export const RealEstateAgencyDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgencyDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Agency phone by agency id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgencyPhoneAgencyById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstatePhoneNumbersDetailPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgencyPhoneAgencyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agency data by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdRealEstateAgencyDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdRealEstateAgencyDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agency redirect data by slug
         * @param {boolean} isBranch 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBySlugRealEstateAgencyRedirect(isBranch: boolean, slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyRedirectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBySlugRealEstateAgencyRedirect(isBranch, slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agents data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealEstateAgencyAgentsByParentId(parentId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyDetailAgentsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealEstateAgencyAgentsByParentId(parentId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Branches data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealEstateAgencyBranchesByParentId(parentId: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyDetailBranchesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealEstateAgencyBranchesByParentId(parentId, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to agency from agency detail page
         * @param {string} id 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailFromAgencyDetailPage(id: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailFromAgencyDetailPage(id, realEstateNotificationEmailDetailPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgencyDetailApi - factory interface
 * @export
 */
export const RealEstateAgencyDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgencyDetailApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Agency phone by agency id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgencyPhoneAgencyById(id: string, options?: any): AxiosPromise<RealEstatePhoneNumbersDetailPageDto> {
            return localVarFp.getAgencyPhoneAgencyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agency data by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgencyDetail(id: string, options?: any): AxiosPromise<RealEstateAgencyDetailDto> {
            return localVarFp.getByIdRealEstateAgencyDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agency redirect data by slug
         * @param {boolean} isBranch 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBySlugRealEstateAgencyRedirect(isBranch: boolean, slug: string, options?: any): AxiosPromise<RealEstateAgencyRedirectDto> {
            return localVarFp.getBySlugRealEstateAgencyRedirect(isBranch, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agents data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealEstateAgencyAgentsByParentId(parentId: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAgencyDetailAgentsDto> {
            return localVarFp.getRealEstateAgencyAgentsByParentId(parentId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Branches data by Parent Id (head office or branch)
         * @param {string} parentId 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealEstateAgencyBranchesByParentId(parentId: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAgencyDetailBranchesDto> {
            return localVarFp.getRealEstateAgencyBranchesByParentId(parentId, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to agency from agency detail page
         * @param {string} id 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailFromAgencyDetailPage(id: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.sendEmailFromAgencyDetailPage(id, realEstateNotificationEmailDetailPageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgencyDetailApi - object-oriented interface
 * @export
 * @class RealEstateAgencyDetailApi
 * @extends {BaseAPI}
 */
export class RealEstateAgencyDetailApi extends BaseAPI {
    /**
     * 
     * @summary Get Agency phone by agency id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public getAgencyPhoneAgencyById(id: string, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).getAgencyPhoneAgencyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agency data by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public getByIdRealEstateAgencyDetail(id: string, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).getByIdRealEstateAgencyDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agency redirect data by slug
     * @param {boolean} isBranch 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public getBySlugRealEstateAgencyRedirect(isBranch: boolean, slug: string, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).getBySlugRealEstateAgencyRedirect(isBranch, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agents data by Parent Id (head office or branch)
     * @param {string} parentId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public getRealEstateAgencyAgentsByParentId(parentId: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).getRealEstateAgencyAgentsByParentId(parentId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Branches data by Parent Id (head office or branch)
     * @param {string} parentId 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public getRealEstateAgencyBranchesByParentId(parentId: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).getRealEstateAgencyBranchesByParentId(parentId, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to agency from agency detail page
     * @param {string} id 
     * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyDetailApi
     */
    public sendEmailFromAgencyDetailPage(id: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any) {
        return RealEstateAgencyDetailApiFp(this.configuration).sendEmailFromAgencyDetailPage(id, realEstateNotificationEmailDetailPageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgencyLandingPageApi - axios parameter creator
 * @export
 */
export const RealEstateAgencyLandingPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageProfiAgencies: async (limit?: number, ignoredAgencyIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agency/landing-page/profi-agencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ignoredAgencyIds !== undefined) {
                localVarQueryParameter['ignoredAgencyIds'] = ignoredAgencyIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgencyLandingPageApi - functional programming interface
 * @export
 */
export const RealEstateAgencyLandingPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgencyLandingPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandingPageProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyProfiAgenciesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandingPageProfiAgencies(limit, ignoredAgencyIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgencyLandingPageApi - factory interface
 * @export
 */
export const RealEstateAgencyLandingPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgencyLandingPageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any): AxiosPromise<RealEstateAgencyProfiAgenciesResponseDto> {
            return localVarFp.getLandingPageProfiAgencies(limit, ignoredAgencyIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgencyLandingPageApi - object-oriented interface
 * @export
 * @class RealEstateAgencyLandingPageApi
 * @extends {BaseAPI}
 */
export class RealEstateAgencyLandingPageApi extends BaseAPI {
    /**
     * 
     * @summary Get Profi Agencies
     * @param {number} [limit] 
     * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyLandingPageApi
     */
    public getLandingPageProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any) {
        return RealEstateAgencyLandingPageApiFp(this.configuration).getLandingPageProfiAgencies(limit, ignoredAgencyIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgencyListingApi - axios parameter creator
 * @export
 */
export const RealEstateAgencyListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRealEstateAgencyListing: async (locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agency/listing/agencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds !== undefined) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiAgencies: async (limit?: number, ignoredAgencyIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agency/listing/profi-agencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ignoredAgencyIds !== undefined) {
                localVarQueryParameter['ignoredAgencyIds'] = ignoredAgencyIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get related Agencies
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRealEstateAgencyListing: async (locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agency/listing/related`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total count of Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalRealEstateAgencyListing: async (locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agency/listing/agencies-total-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds !== undefined) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgencyListingApi - functional programming interface
 * @export
 */
export const RealEstateAgencyListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgencyListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyListingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyProfiAgenciesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiAgencies(limit, ignoredAgencyIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get related Agencies
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedRealEstateAgencyListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RealEstateAgencyListingAgencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total count of Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgencyListingApi - factory interface
 * @export
 */
export const RealEstateAgencyListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgencyListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAgencyListingResponseDto> {
            return localVarFp.findRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profi Agencies
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any): AxiosPromise<RealEstateAgencyProfiAgenciesResponseDto> {
            return localVarFp.getProfiAgencies(limit, ignoredAgencyIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get related Agencies
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRealEstateAgencyListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): AxiosPromise<Array<RealEstateAgencyListingAgencyDto>> {
            return localVarFp.getRelatedRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total count of Agencies by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getTotalRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgencyListingApi - object-oriented interface
 * @export
 * @class RealEstateAgencyListingApi
 * @extends {BaseAPI}
 */
export class RealEstateAgencyListingApi extends BaseAPI {
    /**
     * 
     * @summary Get Agencies by filter
     * @param {string} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyListingApi
     */
    public findRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAgencyListingApiFp(this.configuration).findRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profi Agencies
     * @param {number} [limit] 
     * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyListingApi
     */
    public getProfiAgencies(limit?: number, ignoredAgencyIds?: string, options?: any) {
        return RealEstateAgencyListingApiFp(this.configuration).getProfiAgencies(limit, ignoredAgencyIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get related Agencies
     * @param {Array<string>} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyListingApi
     */
    public getRelatedRealEstateAgencyListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any) {
        return RealEstateAgencyListingApiFp(this.configuration).getRelatedRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total count of Agencies by filter
     * @param {string} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgencyListingApi
     */
    public getTotalRealEstateAgencyListing(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any) {
        return RealEstateAgencyListingApiFp(this.configuration).getTotalRealEstateAgencyListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgentDetailApi - axios parameter creator
 * @export
 */
export const RealEstateAgentDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Agent data by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgentDetail: async (agentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getByIdRealEstateAgentDetail', 'agentId', agentId)
            const localVarPath = `/real-estate/agent/detail/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agent contact by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgentDetailContact: async (agentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getByIdRealEstateAgentDetailContact', 'agentId', agentId)
            const localVarPath = `/real-estate/agent/detail/contact/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to agent
         * @param {string} agentId 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerSendEmailToAgent: async (agentId: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('notificationControllerSendEmailToAgent', 'agentId', agentId)
            // verify required parameter 'realEstateNotificationEmailDetailPageDto' is not null or undefined
            assertParamExists('notificationControllerSendEmailToAgent', 'realEstateNotificationEmailDetailPageDto', realEstateNotificationEmailDetailPageDto)
            const localVarPath = `/real-estate/agent/detail/send-email/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(realEstateNotificationEmailDetailPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgentDetailApi - functional programming interface
 * @export
 */
export const RealEstateAgentDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgentDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Agent data by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdRealEstateAgentDetail(agentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgentDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdRealEstateAgentDetail(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agent contact by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByIdRealEstateAgentDetailContact(agentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstatePhoneNumbersDetailPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByIdRealEstateAgentDetailContact(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to agent
         * @param {string} agentId 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerSendEmailToAgent(agentId: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerSendEmailToAgent(agentId, realEstateNotificationEmailDetailPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgentDetailApi - factory interface
 * @export
 */
export const RealEstateAgentDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgentDetailApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Agent data by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgentDetail(agentId: string, options?: any): AxiosPromise<RealEstateAgentDetailDto> {
            return localVarFp.getByIdRealEstateAgentDetail(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agent contact by agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByIdRealEstateAgentDetailContact(agentId: string, options?: any): AxiosPromise<RealEstatePhoneNumbersDetailPageDto> {
            return localVarFp.getByIdRealEstateAgentDetailContact(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to agent
         * @param {string} agentId 
         * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerSendEmailToAgent(agentId: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerSendEmailToAgent(agentId, realEstateNotificationEmailDetailPageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgentDetailApi - object-oriented interface
 * @export
 * @class RealEstateAgentDetailApi
 * @extends {BaseAPI}
 */
export class RealEstateAgentDetailApi extends BaseAPI {
    /**
     * 
     * @summary Get Agent data by agentId
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentDetailApi
     */
    public getByIdRealEstateAgentDetail(agentId: string, options?: any) {
        return RealEstateAgentDetailApiFp(this.configuration).getByIdRealEstateAgentDetail(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agent contact by agentId
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentDetailApi
     */
    public getByIdRealEstateAgentDetailContact(agentId: string, options?: any) {
        return RealEstateAgentDetailApiFp(this.configuration).getByIdRealEstateAgentDetailContact(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to agent
     * @param {string} agentId 
     * @param {RealEstateNotificationEmailDetailPageDto} realEstateNotificationEmailDetailPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentDetailApi
     */
    public notificationControllerSendEmailToAgent(agentId: string, realEstateNotificationEmailDetailPageDto: RealEstateNotificationEmailDetailPageDto, options?: any) {
        return RealEstateAgentDetailApiFp(this.configuration).notificationControllerSendEmailToAgent(agentId, realEstateNotificationEmailDetailPageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgentLandingPageApi - axios parameter creator
 * @export
 */
export const RealEstateAgentLandingPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Profi Agents
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageProfiAgents: async (limit?: number, ignoredAgencyIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agent/landing-page/profi-agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ignoredAgencyIds !== undefined) {
                localVarQueryParameter['ignoredAgencyIds'] = ignoredAgencyIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgentLandingPageApi - functional programming interface
 * @export
 */
export const RealEstateAgentLandingPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgentLandingPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Profi Agents
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLandingPageProfiAgents(limit?: number, ignoredAgencyIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgentProfiAgentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLandingPageProfiAgents(limit, ignoredAgencyIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgentLandingPageApi - factory interface
 * @export
 */
export const RealEstateAgentLandingPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgentLandingPageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Profi Agents
         * @param {number} [limit] 
         * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageProfiAgents(limit?: number, ignoredAgencyIds?: string, options?: any): AxiosPromise<RealEstateAgentProfiAgentsResponseDto> {
            return localVarFp.getLandingPageProfiAgents(limit, ignoredAgencyIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgentLandingPageApi - object-oriented interface
 * @export
 * @class RealEstateAgentLandingPageApi
 * @extends {BaseAPI}
 */
export class RealEstateAgentLandingPageApi extends BaseAPI {
    /**
     * 
     * @summary Get Profi Agents
     * @param {number} [limit] 
     * @param {string} [ignoredAgencyIds] Ignored Agency IDs separated by comma
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentLandingPageApi
     */
    public getLandingPageProfiAgents(limit?: number, ignoredAgencyIds?: string, options?: any) {
        return RealEstateAgentLandingPageApiFp(this.configuration).getLandingPageProfiAgents(limit, ignoredAgencyIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateAgentListingApi - axios parameter creator
 * @export
 */
export const RealEstateAgentListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAgents: async (locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agent/listing/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds !== undefined) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get related agents
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRealEstateAgentListing: async (locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agent/listing/related`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get total count of Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAgentsCount: async (locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/agent/listing/agents-total-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationIds !== undefined) {
                localVarQueryParameter['locationIds'] = locationIds;
            }

            if (allOtherLocationsExceptTheSelected !== undefined) {
                localVarQueryParameter['allOtherLocationsExceptTheSelected'] = allOtherLocationsExceptTheSelected;
            }

            if (searchNameTerm !== undefined) {
                localVarQueryParameter['searchNameTerm'] = searchNameTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateAgentListingApi - functional programming interface
 * @export
 */
export const RealEstateAgentListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateAgentListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAgents(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgentsListingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAgents(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get related agents
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedRealEstateAgentListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RealEstateAgentListingAgentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedRealEstateAgentListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get total count of Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalAgentsCount(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalAgentsCount(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateAgentListingApi - factory interface
 * @export
 */
export const RealEstateAgentListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateAgentListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAgents(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any): AxiosPromise<RealEstateAgentsListingResponseDto> {
            return localVarFp.findAgents(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get related agents
         * @param {Array<string>} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedRealEstateAgentListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): AxiosPromise<Array<RealEstateAgentListingAgentDto>> {
            return localVarFp.getRelatedRealEstateAgentListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get total count of Agents by filter
         * @param {string} [locationIds] Location IDs separated by comma
         * @param {boolean} [allOtherLocationsExceptTheSelected] 
         * @param {string} [searchNameTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalAgentsCount(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getTotalAgentsCount(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateAgentListingApi - object-oriented interface
 * @export
 * @class RealEstateAgentListingApi
 * @extends {BaseAPI}
 */
export class RealEstateAgentListingApi extends BaseAPI {
    /**
     * 
     * @summary Get Agents by filter
     * @param {string} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentListingApi
     */
    public findAgents(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, skip?: number, limit?: number, options?: any) {
        return RealEstateAgentListingApiFp(this.configuration).findAgents(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get related agents
     * @param {Array<string>} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentListingApi
     */
    public getRelatedRealEstateAgentListing(locationIds?: Array<string>, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any) {
        return RealEstateAgentListingApiFp(this.configuration).getRelatedRealEstateAgentListing(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get total count of Agents by filter
     * @param {string} [locationIds] Location IDs separated by comma
     * @param {boolean} [allOtherLocationsExceptTheSelected] 
     * @param {string} [searchNameTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateAgentListingApi
     */
    public getTotalAgentsCount(locationIds?: string, allOtherLocationsExceptTheSelected?: boolean, searchNameTerm?: string, options?: any) {
        return RealEstateAgentListingApiFp(this.configuration).getTotalAgentsCount(locationIds, allOtherLocationsExceptTheSelected, searchNameTerm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateLocationApi - axios parameter creator
 * @export
 */
export const RealEstateLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all location data for real estate agency listing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/real-estate/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateLocationApi - functional programming interface
 * @export
 */
export const RealEstateLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all location data for real estate agency listing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateAgencyListingLocationCollectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateLocationApi - factory interface
 * @export
 */
export const RealEstateLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateLocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all location data for real estate agency listing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations(options?: any): AxiosPromise<RealEstateAgencyListingLocationCollectionDto> {
            return localVarFp.getLocations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateLocationApi - object-oriented interface
 * @export
 * @class RealEstateLocationApi
 * @extends {BaseAPI}
 */
export class RealEstateLocationApi extends BaseAPI {
    /**
     * 
     * @summary Get all location data for real estate agency listing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateLocationApi
     */
    public getLocations(options?: any) {
        return RealEstateLocationApiFp(this.configuration).getLocations(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RealEstateStatsUserApi - axios parameter creator
 * @export
 */
export const RealEstateStatsUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User advertisements RENT info (average price, rent advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdvertisementsRentInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserAdvertisementsRentInfo', 'id', id)
            const localVarPath = `/real-estate/stats/user/{id}/advertisements/rent/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User advertisements SALE info (average price, sale advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdvertisementsSaleInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserAdvertisementsSaleInfo', 'id', id)
            const localVarPath = `/real-estate/stats/user/{id}/advertisements/sale/info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User portfolio grouped by categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioByCategory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPortfolioByCategory', 'id', id)
            const localVarPath = `/real-estate/stats/user/{id}/portfolio/category`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User portfolio grouped by transaction
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioByTransaction: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPortfolioByTransaction', 'id', id)
            const localVarPath = `/real-estate/stats/user/{id}/portfolio/transaction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RealEstateStatsUserApi - functional programming interface
 * @export
 */
export const RealEstateStatsUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RealEstateStatsUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User advertisements RENT info (average price, rent advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAdvertisementsRentInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateUserAdvertisementsStatsInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAdvertisementsRentInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User advertisements SALE info (average price, sale advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAdvertisementsSaleInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateUserAdvertisementsStatsInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAdvertisementsSaleInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User portfolio grouped by categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPortfolioByCategory(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateUserPortfolioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPortfolioByCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User portfolio grouped by transaction
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPortfolioByTransaction(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealEstateUserPortfolioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPortfolioByTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RealEstateStatsUserApi - factory interface
 * @export
 */
export const RealEstateStatsUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RealEstateStatsUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User advertisements RENT info (average price, rent advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdvertisementsRentInfo(id: string, options?: any): AxiosPromise<RealEstateUserAdvertisementsStatsInfoDto> {
            return localVarFp.getUserAdvertisementsRentInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User advertisements SALE info (average price, sale advertisements count)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdvertisementsSaleInfo(id: string, options?: any): AxiosPromise<RealEstateUserAdvertisementsStatsInfoDto> {
            return localVarFp.getUserAdvertisementsSaleInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User portfolio grouped by categories
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioByCategory(id: string, options?: any): AxiosPromise<RealEstateUserPortfolioDto> {
            return localVarFp.getUserPortfolioByCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User portfolio grouped by transaction
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPortfolioByTransaction(id: string, options?: any): AxiosPromise<RealEstateUserPortfolioDto> {
            return localVarFp.getUserPortfolioByTransaction(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RealEstateStatsUserApi - object-oriented interface
 * @export
 * @class RealEstateStatsUserApi
 * @extends {BaseAPI}
 */
export class RealEstateStatsUserApi extends BaseAPI {
    /**
     * 
     * @summary Get User advertisements RENT info (average price, rent advertisements count)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateStatsUserApi
     */
    public getUserAdvertisementsRentInfo(id: string, options?: any) {
        return RealEstateStatsUserApiFp(this.configuration).getUserAdvertisementsRentInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User advertisements SALE info (average price, sale advertisements count)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateStatsUserApi
     */
    public getUserAdvertisementsSaleInfo(id: string, options?: any) {
        return RealEstateStatsUserApiFp(this.configuration).getUserAdvertisementsSaleInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User portfolio grouped by categories
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateStatsUserApi
     */
    public getUserPortfolioByCategory(id: string, options?: any) {
        return RealEstateStatsUserApiFp(this.configuration).getUserPortfolioByCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User portfolio grouped by transaction
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RealEstateStatsUserApi
     */
    public getUserPortfolioByTransaction(id: string, options?: any) {
        return RealEstateStatsUserApiFp(this.configuration).getUserPortfolioByTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RelationApi - axios parameter creator
 * @export
 */
export const RelationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Advertisement relation for new id
         * @param {string} newId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationForNewId: async (newId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newId' is not null or undefined
            assertParamExists('getAdvertisementRelationForNewId', 'newId', newId)
            const localVarPath = `/relation/advertisement/new/{newId}`
                .replace(`{${"newId"}}`, encodeURIComponent(String(newId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisement relation for old id
         * @param {string} oldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationForOldId: async (oldId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oldId' is not null or undefined
            assertParamExists('getAdvertisementRelationForOldId', 'oldId', oldId)
            const localVarPath = `/relation/advertisement/old/{oldId}`
                .replace(`{${"oldId"}}`, encodeURIComponent(String(oldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisements relations for new ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationsForNewIds: async (bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkAdvertisementsRelationDto' is not null or undefined
            assertParamExists('getAdvertisementRelationsForNewIds', 'bulkAdvertisementsRelationDto', bulkAdvertisementsRelationDto)
            const localVarPath = `/relation/advertisements/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkAdvertisementsRelationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Advertisements relations for old ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationsForOldIds: async (bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkAdvertisementsRelationDto' is not null or undefined
            assertParamExists('getAdvertisementRelationsForOldIds', 'bulkAdvertisementsRelationDto', bulkAdvertisementsRelationDto)
            const localVarPath = `/relation/advertisements/old`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkAdvertisementsRelationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelationApi - functional programming interface
 * @export
 */
export const RelationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement relation for new id
         * @param {string} newId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementRelationForNewId(newId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementRelationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementRelationForNewId(newId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisement relation for old id
         * @param {string} oldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementRelationForOldId(oldId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementRelationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementRelationForOldId(oldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisements relations for new ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdvertisementRelationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Advertisements relations for old ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdvertisementRelationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RelationApi - factory interface
 * @export
 */
export const RelationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement relation for new id
         * @param {string} newId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationForNewId(newId: string, options?: any): AxiosPromise<AdvertisementRelationDto> {
            return localVarFp.getAdvertisementRelationForNewId(newId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisement relation for old id
         * @param {string} oldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationForOldId(oldId: string, options?: any): AxiosPromise<AdvertisementRelationDto> {
            return localVarFp.getAdvertisementRelationForOldId(oldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisements relations for new ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any): AxiosPromise<Array<AdvertisementRelationDto>> {
            return localVarFp.getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Advertisements relations for old ids, max 30 ids
         * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any): AxiosPromise<Array<AdvertisementRelationDto>> {
            return localVarFp.getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelationApi - object-oriented interface
 * @export
 * @class RelationApi
 * @extends {BaseAPI}
 */
export class RelationApi extends BaseAPI {
    /**
     * 
     * @summary Get Advertisement relation for new id
     * @param {string} newId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationApi
     */
    public getAdvertisementRelationForNewId(newId: string, options?: any) {
        return RelationApiFp(this.configuration).getAdvertisementRelationForNewId(newId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisement relation for old id
     * @param {string} oldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationApi
     */
    public getAdvertisementRelationForOldId(oldId: string, options?: any) {
        return RelationApiFp(this.configuration).getAdvertisementRelationForOldId(oldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisements relations for new ids, max 30 ids
     * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationApi
     */
    public getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any) {
        return RelationApiFp(this.configuration).getAdvertisementRelationsForNewIds(bulkAdvertisementsRelationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Advertisements relations for old ids, max 30 ids
     * @param {BulkAdvertisementsRelationDto} bulkAdvertisementsRelationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationApi
     */
    public getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto: BulkAdvertisementsRelationDto, options?: any) {
        return RelationApiFp(this.configuration).getAdvertisementRelationsForOldIds(bulkAdvertisementsRelationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchFilterApi - axios parameter creator
 * @export
 */
export const SearchFilterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get initial search filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInitialSearchFilter: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search-filter/initial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates search url for new advertisements listing
         * @param {ListingSearchUrlFilterRequestDto} listingSearchUrlFilterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newListingSearchUrl: async (listingSearchUrlFilterRequestDto: ListingSearchUrlFilterRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingSearchUrlFilterRequestDto' is not null or undefined
            assertParamExists('newListingSearchUrl', 'listingSearchUrlFilterRequestDto', listingSearchUrlFilterRequestDto)
            const localVarPath = `/search-filter/new-listing-search-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingSearchUrlFilterRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transform filter data to sef
         * @param {SearchFilterDataToSefDtoRequest} searchFilterDataToSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterDataToSef: async (searchFilterDataToSefDtoRequest: SearchFilterDataToSefDtoRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterDataToSefDtoRequest' is not null or undefined
            assertParamExists('searchFilterDataToSef', 'searchFilterDataToSefDtoRequest', searchFilterDataToSefDtoRequest)
            const localVarPath = `/search-filter/to-sef`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterDataToSefDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get filter prices histogram
         * @param {number} itemsCount 
         * @param {SearchFilterHistogramFilterDto} searchFilterHistogramFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterHistogram: async (itemsCount: number, searchFilterHistogramFilterDto: SearchFilterHistogramFilterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemsCount' is not null or undefined
            assertParamExists('searchFilterHistogram', 'itemsCount', itemsCount)
            // verify required parameter 'searchFilterHistogramFilterDto' is not null or undefined
            assertParamExists('searchFilterHistogram', 'searchFilterHistogramFilterDto', searchFilterHistogramFilterDto)
            const localVarPath = `/search-filter/histogram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (itemsCount !== undefined) {
                localVarQueryParameter['itemsCount'] = itemsCount;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterHistogramFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transform filter data from sef
         * @param {SearchFilterDataFromSefDtoRequest} searchFilterDataFromSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterUrlDataFromSef: async (searchFilterDataFromSefDtoRequest: SearchFilterDataFromSefDtoRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchFilterDataFromSefDtoRequest' is not null or undefined
            assertParamExists('searchFilterUrlDataFromSef', 'searchFilterDataFromSefDtoRequest', searchFilterDataFromSefDtoRequest)
            const localVarPath = `/search-filter/from-sef`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilterDataFromSefDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchFilterApi - functional programming interface
 * @export
 */
export const SearchFilterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchFilterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get initial search filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInitialSearchFilter(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilterDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInitialSearchFilter(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates search url for new advertisements listing
         * @param {ListingSearchUrlFilterRequestDto} listingSearchUrlFilterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newListingSearchUrl(listingSearchUrlFilterRequestDto: ListingSearchUrlFilterRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingSearchUrlResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newListingSearchUrl(listingSearchUrlFilterRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transform filter data to sef
         * @param {SearchFilterDataToSefDtoRequest} searchFilterDataToSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFilterDataToSef(searchFilterDataToSefDtoRequest: SearchFilterDataToSefDtoRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterUrlDataDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFilterDataToSef(searchFilterDataToSefDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get filter prices histogram
         * @param {number} itemsCount 
         * @param {SearchFilterHistogramFilterDto} searchFilterHistogramFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFilterHistogram(itemsCount: number, searchFilterHistogramFilterDto: SearchFilterHistogramFilterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistogramResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFilterHistogram(itemsCount, searchFilterHistogramFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transform filter data from sef
         * @param {SearchFilterDataFromSefDtoRequest} searchFilterDataFromSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest: SearchFilterDataFromSefDtoRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterUrlDataDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchFilterApi - factory interface
 * @export
 */
export const SearchFilterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchFilterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get initial search filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInitialSearchFilter(options?: any): AxiosPromise<SearchFilterDataDto> {
            return localVarFp.getInitialSearchFilter(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates search url for new advertisements listing
         * @param {ListingSearchUrlFilterRequestDto} listingSearchUrlFilterRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newListingSearchUrl(listingSearchUrlFilterRequestDto: ListingSearchUrlFilterRequestDto, options?: any): AxiosPromise<ListingSearchUrlResponseDto> {
            return localVarFp.newListingSearchUrl(listingSearchUrlFilterRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transform filter data to sef
         * @param {SearchFilterDataToSefDtoRequest} searchFilterDataToSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterDataToSef(searchFilterDataToSefDtoRequest: SearchFilterDataToSefDtoRequest, options?: any): AxiosPromise<FilterUrlDataDtoResponse> {
            return localVarFp.searchFilterDataToSef(searchFilterDataToSefDtoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get filter prices histogram
         * @param {number} itemsCount 
         * @param {SearchFilterHistogramFilterDto} searchFilterHistogramFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterHistogram(itemsCount: number, searchFilterHistogramFilterDto: SearchFilterHistogramFilterDto, options?: any): AxiosPromise<HistogramResponseDto> {
            return localVarFp.searchFilterHistogram(itemsCount, searchFilterHistogramFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transform filter data from sef
         * @param {SearchFilterDataFromSefDtoRequest} searchFilterDataFromSefDtoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest: SearchFilterDataFromSefDtoRequest, options?: any): AxiosPromise<FilterUrlDataDtoResponse> {
            return localVarFp.searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchFilterApi - object-oriented interface
 * @export
 * @class SearchFilterApi
 * @extends {BaseAPI}
 */
export class SearchFilterApi extends BaseAPI {
    /**
     * 
     * @summary Get initial search filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchFilterApi
     */
    public getInitialSearchFilter(options?: any) {
        return SearchFilterApiFp(this.configuration).getInitialSearchFilter(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates search url for new advertisements listing
     * @param {ListingSearchUrlFilterRequestDto} listingSearchUrlFilterRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchFilterApi
     */
    public newListingSearchUrl(listingSearchUrlFilterRequestDto: ListingSearchUrlFilterRequestDto, options?: any) {
        return SearchFilterApiFp(this.configuration).newListingSearchUrl(listingSearchUrlFilterRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transform filter data to sef
     * @param {SearchFilterDataToSefDtoRequest} searchFilterDataToSefDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchFilterApi
     */
    public searchFilterDataToSef(searchFilterDataToSefDtoRequest: SearchFilterDataToSefDtoRequest, options?: any) {
        return SearchFilterApiFp(this.configuration).searchFilterDataToSef(searchFilterDataToSefDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get filter prices histogram
     * @param {number} itemsCount 
     * @param {SearchFilterHistogramFilterDto} searchFilterHistogramFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchFilterApi
     */
    public searchFilterHistogram(itemsCount: number, searchFilterHistogramFilterDto: SearchFilterHistogramFilterDto, options?: any) {
        return SearchFilterApiFp(this.configuration).searchFilterHistogram(itemsCount, searchFilterHistogramFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transform filter data from sef
     * @param {SearchFilterDataFromSefDtoRequest} searchFilterDataFromSefDtoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchFilterApi
     */
    public searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest: SearchFilterDataFromSefDtoRequest, options?: any) {
        return SearchFilterApiFp(this.configuration).searchFilterUrlDataFromSef(searchFilterDataFromSefDtoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get session according by currently logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get session according by currently logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get session according by currently logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: any): AxiosPromise<SessionDto> {
            return localVarFp.getSession(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @summary Get session according by currently logged user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getSession(options?: any) {
        return SessionApiFp(this.configuration).getSession(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SitemapProvideApi - axios parameter creator
 * @export
 */
export const SitemapProvideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provideControllerGet: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('provideControllerGet', 'name', name)
            const localVarPath = `/sitemap/provide/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitemapProvideApi - functional programming interface
 * @export
 */
export const SitemapProvideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitemapProvideApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provideControllerGet(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.provideControllerGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitemapProvideApi - factory interface
 * @export
 */
export const SitemapProvideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitemapProvideApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provideControllerGet(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.provideControllerGet(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitemapProvideApi - object-oriented interface
 * @export
 * @class SitemapProvideApi
 * @extends {BaseAPI}
 */
export class SitemapProvideApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitemapProvideApi
     */
    public provideControllerGet(name: string, options?: any) {
        return SitemapProvideApiFp(this.configuration).provideControllerGet(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRegistrationApi - axios parameter creator
 * @export
 */
export const UserRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept user GDPR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUserGdpr: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-registration/accept-gdpr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete user registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUserRegistration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-registration/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication x-session-token required
            await setApiKeyToObject(localVarHeaderParameter, "x-session-token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRegistrationApi - functional programming interface
 * @export
 */
export const UserRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accept user GDPR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptUserGdpr(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptUserGdpr(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete user registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeUserRegistration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeUserRegistration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRegistrationApi - factory interface
 * @export
 */
export const UserRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Accept user GDPR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUserGdpr(options?: any): AxiosPromise<void> {
            return localVarFp.acceptUserGdpr(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete user registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUserRegistration(options?: any): AxiosPromise<void> {
            return localVarFp.completeUserRegistration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRegistrationApi - object-oriented interface
 * @export
 * @class UserRegistrationApi
 * @extends {BaseAPI}
 */
export class UserRegistrationApi extends BaseAPI {
    /**
     * 
     * @summary Accept user GDPR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegistrationApi
     */
    public acceptUserGdpr(options?: any) {
        return UserRegistrationApiFp(this.configuration).acceptUserGdpr(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete user registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegistrationApi
     */
    public completeUserRegistration(options?: any) {
        return UserRegistrationApiFp(this.configuration).completeUserRegistration(options).then((request) => request(this.axios, this.basePath));
    }
}


